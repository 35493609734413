import React from 'react';
import HeaderNotifications from './HeaderNotifications';
import HeaderReport from './HeaderReport';
import CurrentUser from './CurrentUser';
import { NavBarRightContainer } from '../StyledComponents/Div';
import Location from './Location';
import RoleCheck from '../Authorization/RoleCheck';

const HeaderIcons = () => (
  <NavBarRightContainer>
    <HeaderReport />
    <HeaderNotifications />
    <RoleCheck type="registration" action="write" yes={() => <Location />} />
    <CurrentUser />
  </NavBarRightContainer>
);

export default HeaderIcons;
