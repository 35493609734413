import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover from '@mui/material/Popover';
import Button from '../Button';
import UnityApi from '../../providers/core/api';
import NotificationList from './NotificationList';
import { CustomBadge } from '../StyledComponents/Material';
import sendError from '../../lib/sendError';

const StorageName = 'notiCount';

class HeaderNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      anchorEl: null,
      isLoading: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.getNotificationCount();
  }

  fetchUnread = async () => {
    this.setLoading();
    const notifications = await new UnityApi('UserAlerts/Unread').request();

    this.setState(
      () => ({ notifications, count: notifications.length }),
      () => {
        this.setLoading();
        this.setNotificationCount();
      }
    );
  };

  setLoading = () =>
    this.setState((prevState) => ({ isLoading: !prevState.isLoading }));

  setNotificationCount = () => {
    const { count } = this.state;
    const obj = { value: count, expiration: new Date(Date.now() + 60000) };
    sessionStorage.setItem(StorageName, JSON.stringify(obj));
  };

  getNotificationCount = () => {
    const notiCount = sessionStorage.getItem(StorageName)
      ? JSON.parse(sessionStorage.getItem(StorageName))
      : null;
    if (notiCount && new Date(notiCount.expiration) > new Date()) {
      this.setState(() => ({ count: notiCount.value }));
    } else {
      this.fetchUnread();
    }
  };

  receivedNotification = async (item) => {
    try {
      if (item.alertId) {
        const path = `UserAlerts/Receive/${item.alertId}`;
        await new UnityApi(path).request({ method: 'PUT' });
        this.fetchUnread();
      }
    } catch (error) {
      sendError(error);
    }
  };

  handleClick = (event) => {
    const { count, notifications } = this.state;
    if (notifications.length !== count) {
      this.fetchUnread();
    }
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => this.setState({ anchorEl: null });

  render() {
    const { count, notifications, isLoading, anchorEl } = this.state;

    return (
      <div className="header-notifications">
        <CustomBadge className="badge" badgeContent={count} color="primary">
          <Button onClick={this.handleClick}>
            <FontAwesomeIcon icon="bell" size="lg" />
          </Button>
        </CustomBadge>
        <Popover
          id="simple-popper"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handleClose}
        >
          <NotificationList
            loading={isLoading}
            data={notifications}
            receivedNotification={this.receivedNotification}
            count={count}
          />
        </Popover>
      </div>
    );
  }
}

export default HeaderNotifications;
