import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

const ReceiptTemplate = () => {
  return renderToStaticMarkup(
    <>
      <div>
        <p style={{ height: 50 }}>&nbsp;</p>
        <p>Thank you for your payment!</p>
        <p>Payment Date: {'{{date}}'}</p>
        <p>Transaction Id: {'{{transactionId}}'}</p>
        <p>
          Guests {'{{qty}}'} X ${'{{cost}}'} = ${'{{total}}'}
        </p>
        <p>
          Dallas Market Center 2100 Stemmons Freeway, Dallas, TX 75207 |
          214.655.6100 or 214.744.7444
        </p>
      </div>
    </>
  );
};

export default ReceiptTemplate;
