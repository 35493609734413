import { useEffect, useState } from 'react';
import sendError from '../../lib/sendError';
import UnityApi from '../../providers/core/api';

const COURTESY = 'courtesy';
const PAID = 'paid guest badge';
const CLIENT = 'client';

export default function useGuestAssociationType() {
  const [paidType, setPaidType] = useState(null);
  const [courtesyType, setCourtesyType] = useState(null);
  const [clientType, setClientType] = useState(null);

  useEffect(() => {
    const getAssociations = async () => {
      try {
        const associationTypes = await new UnityApi(
          'associationType'
        ).request();

        setCourtesyType(
          associationTypes.find(
            (x) => x.associationTypeName.toLowerCase() === COURTESY
          )
        );

        setPaidType(
          associationTypes.find(
            (x) => x.associationTypeName.toLowerCase() === PAID
          )
        );

        setClientType(
          associationTypes.find(
            (x) => x.associationTypeName.toLowerCase() === CLIENT
          )
        );
      } catch (error) {
        sendError(error);
      }
    };
    getAssociations();
  }, []);

  return { paidType, courtesyType, clientType };
}
