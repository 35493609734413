import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { ButtonAdd as Button } from '../StyledComponents/Button';
import { Dialog, DialogContent } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
const PREFIX = 'PrintButton';

const classes = {
  button: `${PREFIX}-button`,
  greenButton: `${PREFIX}-greenButton`,
  dialogPaper: `${PREFIX}-dialogPaper`,
  kioskTextL: `${PREFIX}-kioskTextL`,
  center: `${PREFIX}-center`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.button}`]: {
    width: '100%',
    marginBottom: 25,
    height: 110,
    borderRadius: 0,
    fontSize: '1.8rem',
    fontFamily: 'Work Sans,sans-serif',
  },

  [`& .${classes.greenButton}`]: {
    background: '#009966',
    '&:hover': {
      background: '#00996687',
    },
  },

  [`& .${classes.dialogPaper}`]: {
    minWidth: 'calc(100vw - 500px)',
    minHeight: 'calc(100vh - 400px)',
    '& h2, & button': {
      fontSize: '2rem',
    },
  },

  [`& .${classes.kioskTextL}`]: {
    fontSize: '3rem !important',
    fontFamily: 'Work Sans,sans-serif !important',
  },

  [`& .${classes.center}`]: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
  },
}));

const modalRoot = document.getElementById('root');

export default function PrintButton({ onClick, showDialog, addGuest }) {
  const [openDialog, setOpenDialong] = useState(false);
  const handlePrint = () => {
    onClick();
    setOpenDialong(false);
  };

  return (
    <Root>
      <Button
        className={`${classes.button} ${classes.greenButton}`}
        type="button"
        onClick={showDialog ? () => setOpenDialong(true) : handlePrint}
      >
        <PrintIcon style={{ marginRight: 5, fontSize: '2em' }} />
        Print Badge
      </Button>
      {openDialog &&
        ReactDOM.createPortal(
          <Dialog open={openDialog} classes={{ paper: classes.dialogPaper }}>
            <DialogContent>
              <div className={classes.center}>
                <Button
                  className={classes.button}
                  type="button"
                  onClick={() => {
                    addGuest();
                    setOpenDialong(!openDialog);
                  }}
                >
                  Add Guest
                </Button>
                <div
                  className={classes.kioskTextL}
                  style={{
                    textTransform: 'capitalize',
                    fontWeight: 600,
                    padding: 40,
                    textAlign: 'center',
                  }}
                >
                  OR
                </div>
                <Button
                  className={`${classes.button} ${classes.greenButton}`}
                  type="button"
                  onClick={handlePrint}
                >
                  <PrintIcon style={{ marginRight: 5, fontSize: '2em' }} />
                  Continue to Print Badge
                </Button>
              </div>
            </DialogContent>
          </Dialog>,
          modalRoot
        )}
    </Root>
  );
}

PrintButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  showDialog: PropTypes.bool,
};

PrintButton.defaultProps = { showDialog: true };
