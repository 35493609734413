import moment from 'moment';

export function setAllowPrint(companyAssociation, override = false) {
  let allowPrint;

  // check do not admit (disable print if true)
  allowPrint =
    companyAssociation.company &&
    !companyAssociation.person.doNotAdmit &&
    !companyAssociation.company.doNotAdmit &&
    companyAssociation.isActive &&
    companyAssociation.person.isActive &&
    companyAssociation.company.isActive;

  if (allowPrint && companyAssociation.qualificationStatus != null) {
    allowPrint = companyAssociation.qualificationStatus.isQualified;
  }

  if (override) {
    allowPrint = true;
  }

  return allowPrint;
}

export function willCallAllowPrint(willCall) {
  return willCall.exhibitor.qualificationStatus.isQualified;
}
export function checkValidMarket(market) {
  return moment().isBetween(
    market.registrationStartDate,
    market.registrationEndDate
  );
}
