import Handlebars from 'handlebars/dist/cjs/handlebars';

export default function translateTemplate(source, data) {
  let cleanSource = source;

  // Remove empty spaces from placeholders. handlebar reserved spaces as special helpers.
  const placeholders = source.match(/\{\{(.*?)\}\}/g);
  if (placeholders) {
    placeholders.forEach((x) => {
      if (/\s/g.test(x)) {
        const newPlaceholder = x.replace(/\s/g, '');
        cleanSource = source.replace(x, newPlaceholder);
      }
    });
  }

  const template = Handlebars.compile(cleanSource);
  return template(data);
}

// eslint-disable-next-line func-names
Handlebars.registerHelper('helper_addressLocation', function () {
  const domesticList = ['USA', 'Canada', 'Mexico'];
  return domesticList.some(
    (x) => x.toLowerCase() === this.country.toLowerCase()
  )
    ? `${this.city}, ${this.stateAbbr}`
    : `${this.country}`;
});
