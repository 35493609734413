import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { process } from '@progress/kendo-data-query';
import focusInput from '../../lib/focusInput';

export default function FilteredDropDownList({
  data,
  textField,
  autoSort,
  autoFocus,
  ...props
}) {
  const [filter, setFilter] = useState(null);
  const [focused, setFocused] = useState(false);
  const sort =
    autoSort && textField ? [{ field: textField, dir: 'asc' }] : null;

  const tryFocus = (input) => {
    if (autoFocus && !focused) {
      focusInput(input);
      setFocused(true);
    }
  };

  return (
    <DropDownList
      data={process(data, { filter, sort }).data}
      onFilterChange={(e) => setFilter(e.filter)}
      textField={textField}
      {...props}
      filterable
      ref={tryFocus}
    />
  );
}

FilteredDropDownList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])
  ),
  textField: PropTypes.string,
  autoSort: PropTypes.bool,
  autoFocus: PropTypes.bool,
};
FilteredDropDownList.defaultProps = {
  data: [],
  textField: null,
  autoSort: false,
  autoFocus: false,
};
