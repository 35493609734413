import React from 'react';
import PropTypes from 'prop-types';
import focusInput from '../../lib/focusInput';

function Input({
  receiveChange,
  type,
  className,
  value,
  placeholder,
  onFocus,
  name,
  ...rest
}) {
  return (
    <input
      type={type}
      className={className}
      value={value}
      placeholder={placeholder}
      onChange={(e) => receiveChange(e.target.value)}
      onFocus={onFocus}
      name={name}
      ref={focusInput}
      {...rest}
    />
  );
}

export default Input;

Input.propTypes = {
  receiveChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  className: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  name: PropTypes.string,
};
Input.defaultProps = { type: 'text', placeholder: '', name: '', onFocus: null };
