import UnityApi from './core/api';
import sendError from '../lib/sendError';

class UserConfiguration {
  constructor() {
    this.api = new UnityApi('UserSetting');
  }

  get = async (key, signal = null) => {
    try {
      const setting = await this.api.request({
        path: key,
        method: 'GET',
        signal,
      });
      return JSON.parse(setting);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  save = (key, value, signal = null) => {
    try {
      return this.api.request({
        method: 'POST',
        data: { setting: key, value: JSON.stringify(value) },
        signal,
      });
    } catch (error) {
      sendError(error);
      return null;
    }
  };
}

export default UserConfiguration;
