import { useEffect, useState } from 'react';
import sendError from '../../lib/sendError';
import { getBadgeTemplate } from '../../providers/registration';

export default function useGuestBadgeTemplate() {
  const [guestBadgeTemplate, setGuestBadgeTemplate] = useState(null);
  useEffect(() => {
    const getTemplate = async () => {
      try {
        const badgeTemplate = await getBadgeTemplate(2);
        setGuestBadgeTemplate(badgeTemplate);
      } catch (error) {
        sendError(error);
      }
    };
    getTemplate();
  }, []);

  return guestBadgeTemplate;
}
