import React from 'react';
import PropTypes from 'prop-types';
import UnityApi from '../../providers/core/api';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, { componentStack: stackTrace }) {
    const data = {
      type: 'Render',
      errorMessage: error.toString(),
      stackTrace,
      url: window.location.href,
    };
    new UnityApi('Error').request({ method: 'POST', data });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <p>An error has occured</p>;
    }
    return children;
  }
}

export default ErrorBoundary;

ErrorBoundary.propTypes = { children: PropTypes.node };
ErrorBoundary.defaultProps = { children: null };
