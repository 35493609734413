import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import Input from '../Input';
import UserConfiguration from '../../providers/settings';
import { DefaultToggleButtonGroup } from '../StyledComponents/Material';
import checkIsNumeric from '../../lib/checkRegistration';
import { useNavigate } from 'react-router-dom';

export default function HeaderSearch({
  searchType,
  setSearchType,
  query,
  setQuery,
}) {
  let placeholder = '';
  if (searchType === 'people') {
    placeholder = 'Search by person name';
  }
  if (searchType === 'company') {
    placeholder = 'Search by company name';
  }

  const handleValueChange = (searchValue) => {
    setQuery(searchValue);
  };

  const onChange = (value) => {
    if (value !== null) {
      new UserConfiguration().save('searchType', value);
      setSearchType(value);
    }
  };

  let navigate = useNavigate();

  const handleSubmit = (event) => {
    // Handle Search Query is FP Registration
    if (checkIsNumeric(query)) {
      event.preventDefault();
      const registration = query;
      //Reset Focus
      setQuery('');
      event.target.querySelector('input[name="query"]').focus();
      // Redirect to registration FP View
      navigate(`/registration/${registration}`);
    }
  };

  return (
    <div>
      <form className="nav-search" action="/search" onSubmit={handleSubmit}>
        <div className="nav-search__select-container">
          <DefaultToggleButtonGroup
            value={searchType}
            exclusive
            onChange={(event, value) => onChange(value)}
            size="small"
          >
            <ToggleButton value="people">Person</ToggleButton>
            <ToggleButton value="company">Company</ToggleButton>
          </DefaultToggleButtonGroup>
          <input hidden name="searchType" value={searchType} readOnly />
        </div>
        <Input
          placeholder={placeholder}
          value={query}
          className="nav-search__field"
          receiveChange={handleValueChange}
          name="query"
          type="search"
        />
        <input type="submit" value="search" className="nav-search__action" />
      </form>
    </div>
  );
}

HeaderSearch.propTypes = {
  searchType: PropTypes.string.isRequired,
  setSearchType: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
};
