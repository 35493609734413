import UnityApi from './core/api';

export const getBadgeTemplate = async (
  attendanceTypeId,
  associationTypeId = 0,
  marketId = 0
) => {
  const url = `registration/badge?attendanceTypeId=${attendanceTypeId}&associationTypeId=${associationTypeId}&marketId=${marketId}`;
  const badgeTemplate = await new UnityApi(url).request();
  return badgeTemplate;
};

export const createAttendanceAndPrint = async (data) =>
  new UnityApi('Attendance/Print').request({ method: 'POST', data });
