import React from 'react';
import BrandLogo from '../../assets/dmc-star-transparent.png';
import '../../styles/layout/_login.scss';
import '../../styles/common.scss';
import { useAuth } from 'react-oidc-context';

export default function Login() {
  const auth = useAuth();

  return (
    <>
      <div className="sign-in-bg"></div>

      <div className="sign-in">
        <div className="sign-in__bg"></div>
        <img
          className="brand-logo"
          src={BrandLogo}
          alt="Dallas Market Center logo"
        />
        <div className="sign-in__title">Unity</div>
        <div className="sign-in__container">
          <div className="sign-in__section">
            <h3 className="sign-in__welcome">Welcome back!</h3>
          </div>
          <div className="sign-in__section">
            <a href="signinexternal" className="sign-in__msft">
              Sign in with Microsoft
            </a>
          </div>
          <div className="sign-in__section">
            <div className="sign-in__alt-option">
              or login with your DMC account
            </div>
          </div>
          <div className="sign-in__section">
            <div method="post" className="sign-in__fields">
              <div className="sign-in__field-container">
                <label htmlFor="username" className="sign-in__field-label">
                  Username
                </label>
                <input
                  name="username"
                  type="text"
                  className="sign-in__field-input"
                  placeholder="Username"
                  autoCorrect="off"
                  spellCheck="false"
                />
              </div>
              <div className="sign-in__field-container">
                <label htmlFor="password" className="sign-in__field-label">
                  Password
                </label>
                <input
                  name="password"
                  type="password"
                  className="sign-in__field-input"
                  placeholder="Password"
                />
              </div>
              <div className="sign-in__submit-container">
                <button
                  type="button"
                  onClick={() => void auth.signinRedirect()}
                  className="sign-in__submit"
                >
                  Sign in to your Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
