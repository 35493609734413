import React, { useState, useEffect, useContext, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Input } from '@progress/kendo-react-inputs';
import { LinearProgress, Typography } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import Container from '@mui/material/Container';
import { useLocation } from 'react-router-dom';
import UnityApi from '../../providers/core/api';
import { KioskContainer, KioskBody } from '../StyledComponents/Div';
import focusInput from '../../lib/focusInput';
import Logo from '../../assets/dmc-star-fp-sm.png';
import { registrations as testRegs } from '../../Test/mockData';
import RegistrationPanel from './RegistrationPanel';
import { PRINTTIME, TIMEOUT } from './constants';
import BadgePanel from './BadgePanel';
import { UserContext } from '../../context/UserContext';
import ErrorPanel from './ErrorPanel';
import { checkValidMarket, setAllowPrint } from '../../lib/buttonHelper';
import {
  createAttendanceAndPrint,
  getBadgeTemplate,
} from '../../providers/registration';
import { BadgePrintStyle } from '../Registration/BadgePrintStyle';
import translateTemplate from '../../lib/templating';
import useGuestBadgeTemplate from './useGuestBadgeTemplate';
import sendError from '../../lib/sendError';
import StickyFooter from './Footer';
import {
  generatePaymentReceipt,
  useTestMode,
} from '../../lib/guestRegistrationUtil';

const PREFIX = 'Kiosk';

const classes = {
  mainContainer: `${PREFIX}-mainContainer`,
  mainText: `${PREFIX}-mainText`,
  subText: `${PREFIX}-subText`,
  barcodeInput: `${PREFIX}-barcodeInput`,
};

const StyledKioskContainer = styled(KioskContainer)(() => ({
  [`& .${classes.mainContainer}`]: {
    marginTop: 64,
    marginBottom: 64,
  },

  [`& .${classes.mainText}`]: {
    textTransform: 'uppercase',
    marginBottom: 50,
    fontFamily: 'Roboto Condensed, sans-serif',
    fontWeight: '900 !important',
  },

  [`& .${classes.subText}`]: {
    marginBottom: 50,
    backgroundColor: 'rgb(218 218 218 / 80%)',
    padding: '1rem',
    fontSize: '4rem',
    textTransform: 'capitalize',
    borderRadius: 2,
    fontFamily: 'Work Sans,sans-serif',
    '@media (min-width: 1930px)': {
      marginLeft: 250,
      marginRight: 250,
    },
  },

  [`& .${classes.barcodeInput}`]: {
    width: '50% !important',
    textAlign: 'center !important',
    fontSize: 'xx-large !important',
  },
}));

export default function Kiosk() {
  const [barcode, setBarcode] = useState('');
  const [registration, setRegistration] = useState(null);
  const [companyAssociation, setCompanyAssociation] = useState(null);
  const [badgeTemplate, setBadgeTemplate] = useState(null);
  const [market, setMarket] = useState(null);
  const { registrationLocation } = useContext(UserContext);
  const [guestList, setGuestList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [print, setPrint] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [testBarcode, setTestBarcode] = useState(false);
  const [tags, setTags] = useState(null);
  const [vip, setVip] = useState(false);
  const location = useLocation();
  const testMode = useTestMode(location).testMode;
  const testSwipe = useTestMode(location).testSwipe;

  const clearData = () => {
    setBarcode('');
    setTestBarcode(false);
    setErrorMessage(null);
    setGuestList([]);
    setRegistration(null);
    setMarket(null);
    setCompanyAssociation(null);
    setLoading(false);
    setBadgeTemplate(null);
    setPrint(false);
    setTransaction(null);
    setTags(null);
    setVip(false);
  };

  const recordBadgePrint = async () => {
    setLoading(true);
    try {
      /* Check TestBarcode. Skip Attendance */
      if (!testBarcode) {
        if (registration) {
          // Create Attendance and Print for FP Registration
          await createAttendanceAndPrint({
            registrationId: registration.registrationId,
            attendanceTypeId:
              registration.companyAssociation.associationType.attendanceType
                .attendanceTypeId,
            badgeId: badgeTemplate.badgeId,
            registrationLocation,
          });
        } else if (companyAssociation && market) {
          // Create Registration, Attendance and Print for VIP
          await new UnityApi('Registration/RegisterandPrint').request({
            method: 'POST',
            data: { companyAssociation, market, registrationLocation },
          });
        }

        const payment =
          transaction && transaction.payment ? transaction.payment : null;

        const registerGuests = guestList.map(async (guest) => {
          await new UnityApi('Registration/Guest').request({
            method: 'POST',
            data: {
              person: { firstName: guest.firstName, lastName: guest.lastName },
              hostCompanyAssociation: companyAssociation,
              associationType: guest.associationType,
              market: market,
              registrationLocation,
              payment,
            },
          });
        });
        await Promise.all(registerGuests);
      }
    } catch (error) {
      sendError(error);
    }
    setTimeout(clearData, PRINTTIME);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: recordBadgePrint,
    copyStyles: false,
    pageStyle: BadgePrintStyle,
  });

  // Scan barcode
  useEffect(() => {
    // Fake Barcode for Testing
    const getTestRegistration = async (id) => {
      const reg = testRegs.find((x) => x.registrationId === Number(id));
      setTestBarcode(true);
      setRegistration(reg);
      setCompanyAssociation(reg.companyAssociation);
      setMarket(reg.market);
    };

    if (barcode === '100111' || barcode === '100222') {
      getTestRegistration(barcode);
      return;
    }

    // fastpass barcode
    const getRegistration = async () => {
      const reg = await new UnityApi(`registration/${barcode}`).request();

      const valid =
        reg &&
        !!reg.companyAssociation &&
        setAllowPrint(reg.companyAssociation) &&
        !!reg.market &&
        checkValidMarket(reg.market);

      if (!valid) {
        setTimeout(clearData, TIMEOUT);
        setErrorMessage(
          'Your registration is not valid for this market. Please speak to a Registration Staff Member for assistance.'
        );
        return;
      }

      /* Check Re-Print. Allow On TestMode */
      if (!testMode) {
        const { attendanceId } = await new UnityApi(
          `registration/status/${barcode}`
        ).request();

        if (attendanceId > 0) {
          setErrorMessage(
            'A badge has already been printed for this registration. Please speak to a Registration Staff Member for assistance.'
          );
          setTimeout(clearData, TIMEOUT);
          return;
        }
      }

      setRegistration(reg);
      setCompanyAssociation(reg.companyAssociation);
      setMarket(reg.market);
    };

    const regex = /^\d{8,9}$/;
    if (regex.test(barcode)) {
      getRegistration(barcode);
      return;
    }

    // vip barcode
    const fetchAccessBadge = async () => {
      try {
        const ca = await new UnityApi(
          `companyAssociation/accessbadge/${barcode}`
        ).request();

        // fetch market for VIP
        const [firstVipMarket] = await new UnityApi(
          'Registration/OngoingMarkets'
        ).request();

        const isValid =
          ca &&
          ca.person &&
          firstVipMarket &&
          ca.isActive &&
          ca.person?.isActive &&
          !ca.person?.doNotAdmit &&
          ca.qualificationStatus?.isQualified;
        if (isValid) {
          setCompanyAssociation(ca);
          setMarket(firstVipMarket);
          setVip(true);
        } else {
          setErrorMessage(
            'Your badge is not valid. Please speak to a Registration Staff Member for assistance.'
          );
          setTimeout(clearData, TIMEOUT);
        }
      } catch (error) {
        setErrorMessage(
          'Something went wrong. Try again or speak to a Registration Staff Member for assistance.'
        );
        setTimeout(clearData, TIMEOUT);
      }
    };

    if (barcode.length === 10) {
      fetchAccessBadge();
    }
  }, [barcode, testMode]);

  // Set up badge template
  useEffect(() => {
    const fetchTemplate = async () => {
      const template = await getBadgeTemplate(
        companyAssociation.associationType.attendanceType.attendanceTypeId,
        companyAssociation.associationType.associationTypeId,
        market.marketId
      );
      setBadgeTemplate(template);
    };

    const fetchTags = async () => {
      const regTags = await new UnityApi(
        `Tag/RegistrationTags?companyId=${companyAssociation.companyId}&personId=${companyAssociation.personId}`
      ).request();

      setTags(regTags);
    };

    if (companyAssociation && market) {
      fetchTemplate();
      fetchTags();
    }
  }, [companyAssociation, market]);

  // Update guest list
  const [guestBadges, setGuestBadges] = useState([]);
  const guestBadgeTemplate = useGuestBadgeTemplate();
  useEffect(() => {
    if (guestBadgeTemplate && guestList.length >= 0) {
      const currentDate = moment().format('MM/DD/YYYY');
      const list = guestList.map((g) =>
        translateTemplate(guestBadgeTemplate.badgeHtml, {
          firstName: g.firstName,
          lastName: g.lastName,
          currentDate,
          associationType: g.associationType?.associationTypeName,
          companyName: companyAssociation?.company?.companyName,
        })
      );
      setGuestBadges(list);
    }
  }, [companyAssociation, guestBadgeTemplate, guestList]);

  const printWithReciept = (transactionResult) => {
    // Print Reciept
    const template = generatePaymentReceipt(transactionResult);
    setGuestBadges([...guestBadges, template]);

    // Log payment transaction and Print
    setPrint(true);
    setTransaction(transactionResult);
  };

  /* Hook to trigger print after payment transaction */
  useEffect(() => {
    const { pnref } = transaction || {};
    if (pnref && print) {
      handlePrint();
      setPrint(false);
    }
  }, [handlePrint, print, transaction]);

  return (
    <StyledKioskContainer>
      <div>
        <img
          src={Logo}
          alt="Dallas Market Center"
          style={{
            position: 'absolute',
            left: 40,
            top: 40,
            zIndex: 1,
            width: '100%',
            maxWidth: 450,
            height: 'auto',
          }}
        />
      </div>
      <div id="animation" />
      {errorMessage ? (
        <ErrorPanel message={errorMessage} />
      ) : (
        <>
          {!companyAssociation && (
            <KioskBody>
              <Container
                component="main"
                className={classes.mainContainer}
                maxWidth="xl"
              >
                <Typography variant="h1" className={classes.mainText}>
                  Welcome
                </Typography>
                <Typography variant="h5" className={classes.subText}>
                  Please scan your FastPass or <br />
                  VIP Designer Badge
                </Typography>
                <div>
                  <Input
                    value={barcode}
                    onChange={(e) => setBarcode(e.target.value)}
                    ref={focusInput}
                    className={classes.barcodeInput}
                    onBlur={(e) => e.target.focus()}
                  />
                </div>
              </Container>
            </KioskBody>
          )}
          {companyAssociation && market && (
            <RegistrationPanel
              companyAssociation={companyAssociation}
              market={market}
              guests={guestList}
              updateGuests={setGuestList}
              vip={vip}
              handlePrint={handlePrint}
              recordScan={recordBadgePrint}
              cancel={clearData}
              printWithReciept={printWithReciept}
              printing={loading}
              testSwipe={testSwipe}
            />
          )}
          {market && (
            <BadgePanel
              badgeTemplate={badgeTemplate || ''}
              componentRef={componentRef}
              market={market}
              companyAssociation={companyAssociation}
              guestBadges={guestBadges}
              tags={tags}
            />
          )}
        </>
      )}
      {loading && <LinearProgress />}
      <StickyFooter testMode={testMode} testSwipe={testSwipe} />
    </StyledKioskContainer>
  );
}
