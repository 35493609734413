import React, { useEffect, useCallback } from 'react';
import { useAuth } from 'react-oidc-context';
import { getSiteSettings } from '../providers/authService';

export default function Signout() {
  const auth = useAuth();
  const { redirect } = getSiteSettings();

  const handleSignout = useCallback(() => {
    setTimeout(() => {
      auth.signoutRedirect({
        post_logout_redirect_uri: redirect,
      });
    }, 1000);
  }, [auth, redirect]);

  useEffect(() => {
    handleSignout();
  }, [auth, handleSignout, redirect]);

  return (
    <>
      <div>Signing you out...</div>
    </>
  );
}
