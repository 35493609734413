import React from 'react';
import PropTypes from 'prop-types';
import { Container, LinearProgress } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { KioskBody } from '../StyledComponents/Div';

export default function ErrorPanel({ message }) {
  return (
    <KioskBody>
      <Container style={{ minHeight: 500, minWidth: 500 }}>
        <div
          style={{
            padding: 10,
            fontSize: '6em',
            fontWeight: 600,
            textTransform: 'uppercase',
            fontFamily: 'Roboto Condensed, sans-serif',
            marginTop: 30,
          }}
        >
          <ErrorOutlineIcon
            style={{ fontSize: '1em', top: 5, right: 5, position: 'relative' }}
          />
          We&apos;re Sorry!
        </div>
        <div
          style={{
            fontSize: '4em',
            padding: 10,
            fontFamily: 'Work Sans,sans-serif',
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          {message}
        </div>
        <LinearProgress />
      </Container>
    </KioskBody>
  );
}

ErrorPanel.propTypes = { message: PropTypes.string.isRequired };
