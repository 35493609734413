/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, lazy, useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import '../../styles/common.scss';
import uri from 'urijs';
import Loader from '../Loader';
import ErrorBoundary from '../ErrorBoundary';
import RoleCheck from '../Authorization/RoleCheck';
import Header from '../Header';
import Navigation from '../Navigation';
import UserConfiguration from '../../providers/settings';
import sendError from '../../lib/sendError';

const HomeView = lazy(
  () => import(/* webpackChunkName: "homeView" */ '../Dashboard')
);
const GroupView = lazy(
  () => import(/* webpackChunkName: "groupView" */ '../Groups')
);
const SearchView = lazy(
  () => import(/* webpackChunkName: "searchView" */ '../Search')
);
const AdminView = lazy(
  () => import(/* webpackChunkName: "adminView" */ '../Admin')
);
const ReportView = lazy(
  () => import(/* webpackChunkName: "reportView" */ '../Reports')
);
const LeadsView = lazy(
  () => import(/* webpackChunkName: "leadsView" */ '../Leads')
);
const ErrorView = lazy(
  () => import(/* webpackChunkName: "errorView" */ '../Error')
);
const VisitorView = lazy(
  () => import(/* webpackChunkName: "visitorView" */ '../Visitor')
);
const PersonView = lazy(
  () => import(/* webpackChunkName: "personView" */ '../Details/personDetails')
);
const CompanyView = lazy(
  () =>
    import(/* webpackChunkName: "companyView" */ '../Details/companyDetails')
);
const UserAdminView = lazy(
  () => import(/* webpackChunkName: "userAdminView" */ '../UserAdmin')
);
const SettingsView = lazy(
  () => import(/* webpackChunkName: "settingsView" */ '../UserProfile')
);
const NotificationsView = lazy(
  () =>
    import(
      /* webpackChunkName: "settingsView" */ '../UserProfile/Notifications'
    )
);
const FastPassView = lazy(
  () => import(/* webpackChunkName: "fastPassView" */ '../FastPass')
);
const BuyListView = lazy(
  () => import(/* webpackChunkName: "buyListView" */ '../BuyList')
);
const ContractorView = lazy(
  () => import(/* webpackChunkName: "contractorView" */ '../Contractor')
);
const WillCallView = lazy(
  () => import(/* webpackChunkName: "willCallView" */ '../WillCall')
);

const BoothLeasingView = lazy(
  () => import(/* webpackChunkName: "boothLeasing" */ '../BoothLeasing')
);
export default function Router() {
  const { searchType: searchTypeParam, query: queryParam } = uri(
    window.location.href
  ).search(true);
  const [loading, setLoading] = useState(false);
  const [searchType, setSearchType] = useState(searchTypeParam || 'people');
  const [query, setQuery] = useState(queryParam || '');

  useEffect(() => {
    const fetchSearchType = async () => {
      try {
        const type = await new UserConfiguration().get('searchType');
        if (type) {
          setSearchType(type);
        }
      } catch (error) {
        sendError(error);
      }
    };

    if (!searchTypeParam) {
      fetchSearchType();
    }
  }, [searchTypeParam]);

  const updateLoading = (update) => setLoading(update);

  const redirect = () => {
    return <Navigate to="/" replace />;
  };

  return (
    <ErrorBoundary>
      <Header
        setSearchType={setSearchType}
        searchType={searchType}
        query={query}
        setQuery={setQuery}
      />
      <Navigation />
      <Container>
        {loading === true && <Loader />}
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path="search"
              element={
                <SearchView
                  spinner={updateLoading}
                  searchType={searchType}
                  setSearchType={setSearchType}
                  query={query}
                  setQuery={setQuery}
                />
              }
            />
            <Route path="company/:type/:id" element={<CompanyView />} />
            <Route path="company/:id" element={<CompanyView />} />
            <Route path="group/:id" element={<GroupView />} />
            <Route path="group" element={<GroupView />} />
            <Route
              path="leads"
              element={
                <RoleCheck
                  type="leads"
                  action="read"
                  yes={() => <LeadsView />}
                  no={redirect}
                />
              }
            />
            <Route path="person/:id" element={<PersonView />} />
            <Route path="account/settings" element={<SettingsView />} />
            <Route
              path="account/notifications"
              element={<NotificationsView />}
            />
            <Route
              path="admin/*"
              element={
                <RoleCheck
                  type="admin"
                  action="read"
                  yes={() => <AdminView />}
                  no={redirect}
                />
              }
            />
            <Route
              path="useradmin/*"
              element={
                <RoleCheck
                  type="userAdmin"
                  action="read"
                  yes={() => <UserAdminView />}
                  no={redirect}
                />
              }
            />
            <Route path="Registration/:id" element={<FastPassView />} />
            <Route path="report/*" element={<ReportView />} />
            <Route path="report/buylist" element={<BuyListView />} />
            <Route
              path="contractor"
              element={
                <RoleCheck
                  type="contractor"
                  action="read"
                  yes={() => <ContractorView />}
                  no={redirect}
                />
              }
            />
            <Route
              path="visitor"
              element={
                <RoleCheck
                  type="visitor"
                  action="read"
                  yes={() => <VisitorView />}
                  no={redirect}
                />
              }
            />
            <Route
              path="willcall"
              element={
                <RoleCheck
                  type="willCall"
                  action="read"
                  yes={() => <WillCallView />}
                  no={redirect}
                />
              }
            />
            <Route
              path="boothleasing/*"
              element={
                <RoleCheck
                  type="boothLeasing"
                  action="read"
                  yes={() => <BoothLeasingView />}
                  no={redirect}
                />
              }
            />
            <Route path="error" element={<ErrorView />} />
            <Route path="/" element={<HomeView />} />
          </Routes>
        </Suspense>
      </Container>
    </ErrorBoundary>
  );
}

const Container = styled.div`
  max-width: 100%;
`;
