import React, { useEffect, useState, useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Divider,
  Typography,
  Button as MaterialButton,
  Container,
  Paper,
  Alert,
} from '@mui/material';
import { Input, MaskedTextBox } from '@progress/kendo-react-inputs';
import { KioskPaymentContainer } from '../StyledComponents/Div';
import CardImage from '../../assets/dmc-cardswipe.png';
import UnityApi from '../../providers/core/api';
import { GUESTPRICE, TESTSWIPECARD } from '../../lib/guestRegistrationUtil';
import { debounce } from 'throttle-debounce';
import currencyFormat from '../../lib/currencyFormat';
import Loader from '../Loader';
const PREFIX = 'PaymentPanel';

const classes = {
  kioskAlert: `${PREFIX}-kioskAlert`,
  kioskTextS: `${PREFIX}-kioskTextS`,
  kioskTextXS: `${PREFIX}-kioskTextXS`,
  card: `${PREFIX}-card`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.kioskAlert}`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
  },

  [`& .${classes.kioskTextS}`]: {
    fontSize: '2rem !important',
    fontFamily: 'Work Sans,sans-serif !important',
  },

  [`& .${classes.kioskTextXS}`]: {
    fontSize: '1.5rem !important',
    fontFamily: 'Work Sans,sans-serif !important',
  },

  [`& .${classes.card}`]: {
    padding: '20px 0 10px 0',
  },
}));

const PaymentPanel = (props) => {
  const {
    setShowPayment,
    showPayment,
    setErrorMessage,
    errorMessage,
    printWithReciept,
    amount,
    guestQty,
    testSwipe,
    paymentRequired,
    isKioskView,
  } = props;

  const [cardNumber, setCardNumber] = useState('');
  const [expDate, setExpDate] = useState('');
  const [swipe, setSwipe] = useState('');
  const [swipeReadInput, setSwipeReadInput] = useState('');
  const [showManualPayment, setShowManualPayment] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const mask =
    cardNumber.startsWith('34') || cardNumber.startsWith('37')
      ? '0000 000000 00000'
      : '0000 0000 0000 0000';

  const inputFocusCC = useRef(null);
  const inputFocusSwipe = useRef(null);

  const payGuests = useCallback(async () => {
    setPaymentLoading(true);

    const resp = await new UnityApi('payment').request({
      method: 'POST',
      data: {
        cardNumber,
        amount,
        expDate,
        swipe: testSwipe ? TESTSWIPECARD : swipe,
      },
    });

    if (resp?.result === 0) {
      setShowPayment(false);
      setErrorMessage(null);
      printWithReciept({
        ...resp,
        guestPrice: GUESTPRICE,
        guestQty: guestQty,
        paymentAmount: amount,
      });
    } else {
      setErrorMessage(resp.respMsg);
    }

    setSwipe('');
    setSwipeReadInput('');
    setPaymentLoading(false);
  }, [cardNumber, expDate, swipe]);

  const debounceSwipe = useCallback(
    debounce(600, (value) => {
      setSwipe(value);
    }),
    []
  );

  const readSwipe = (event) => {
    setSwipeReadInput(event.target.value);
    debounceSwipe(event.target.value);
  };

  useEffect(() => {
    // Determine when swipe complete, we'll automaticlly send the payment
    if (swipe !== '' && swipe.endsWith('?')) {
      payGuests();
    }
  }, [payGuests, swipe]);

  useEffect(() => {
    if (showManualPayment) {
      inputFocusCC.current.focus();
    } else {
      inputFocusSwipe.current.focus();
    }
  }, [showManualPayment]);

  useEffect(() => {
    const handleFocusClick = (event) => {
      if (
        inputFocusSwipe.current &&
        inputFocusSwipe.current.element !== event.target &&
        !inputFocusCC.current
      ) {
        inputFocusSwipe.current.focus();
      }
    };

    // Adding a click event listener to re-focus the input
    document.addEventListener('click', handleFocusClick);
  }, []);

  return (
    <Root>
      <Container>
        {errorMessage && (
          <Alert severity="error" className={classes.kioskAlert}>
            <div className={classes.kioskTextS}>
              Sorry, there was an error with proccessing your payment.{' '}
              {errorMessage}
            </div>
          </Alert>
        )}
        {paymentLoading && <Loader usePosition="absolute" />}
        <div className={classes.card}>
          <Typography
            style={{ fontWeight: 600 }}
            className={classes.kioskTextS}
          >
            Amount Due {currencyFormat.format(amount)}
          </Typography>
        </div>
        <img
          alt=""
          src={CardImage}
          style={{ maxWidth: 300, objectFit: 'cover' }}
        />
        <Typography
          style={{ paddingBottom: '10px', fontWeight: 600 }}
          className={classes.kioskTextS}
        >
          Credit Card Payment
        </Typography>
        <Typography
          style={{
            paddingBottom: '10px',
            textTransform: 'capitialize',
            fontWeight: 600,
          }}
          className={classes.kioskTextXS}
        >
          Please swipe credit card to complete payment
        </Typography>
        {isKioskView && (
          <Typography
            style={{ padding: '0 20px 0 20px' }}
            className={classes.kioskTextXS}
            component="div"
          >
            Your badges will be printed upon completion. By proceeding,
            you&apos;ve agreed to the guest policy at DMC and acknowledged that
            the payment of{' '}
            <div>{currencyFormat.format(amount)} is non-refundable.</div>
          </Typography>
        )}

        <div style={{ width: 0, overflow: 'hidden' }}>
          <Input
            value={swipeReadInput}
            onChange={readSwipe}
            ref={inputFocusSwipe}
          />
        </div>
        <MaterialButton
          onClick={() => setShowManualPayment(!showManualPayment)}
          style={{ marginRight: 50, display: 'none' }}
        >
          {showManualPayment ? 'Hide Manual Card' : 'Key In Card'}
        </MaterialButton>
        <MaterialButton
          style={{ border: '1px solid #c3c3c3' }}
          onClick={() => {
            setShowPayment(!showPayment);
            setErrorMessage(null);
          }}
        >
          Cancel
        </MaterialButton>
      </Container>
      {showManualPayment && (
        <Paper>
          {guestQty > 0 && paymentRequired && showPayment && (
            <KioskPaymentContainer>
              <Typography variant="h5">Card Payment</Typography>
              <Divider />
              <MaskedTextBox
                label="Card Number"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
                mask={mask}
                prompt=""
                ref={inputFocusCC}
              />

              <MaskedTextBox
                label="Exp Date"
                value={expDate}
                onChange={(e) => setExpDate(e.target.value)}
                mask="00/00"
              />
              <MaterialButton
                color="primary"
                variant="contained"
                size="large"
                onClick={() => payGuests()}
                disabled={
                  cardNumber.trim().length < 1 && expDate.trim().length < 1
                }
                style={{ margin: '20px 0 0 10px' }}
              >
                Pay
              </MaterialButton>
            </KioskPaymentContainer>
          )}
        </Paper>
      )}
    </Root>
  );
};

export default PaymentPanel;

PaymentPanel.propTypes = {
  setShowPayment: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  printWithReciept: PropTypes.func.isRequired,
  guestQty: PropTypes.number,
  amount: PropTypes.number,
  testSwipe: PropTypes.bool,
  errorMessage: PropTypes.string,
  paymentRequired: PropTypes.bool,
  showPayment: PropTypes.bool,
  isKioskView: PropTypes.bool,
};

PaymentPanel.defaultProps = {
  guestQty: 0,
  amount: 0,
  testSwipe: true,
  errorMessage: '',
  paymentRequired: false,
  showPayment: false,
  isKioskView: false,
};
