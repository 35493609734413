import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const Loader = (props) => {
  const { usePosition } = props;

  return (
    <Wrapper usePosition={usePosition}>
      <StyledSpinner width="65px" height="65px" viewBox="0 0 66 66">
        <StyledCircle />
      </StyledSpinner>
    </Wrapper>
  );
};

Loader.propTypes = { usePosition: PropTypes.string };
Loader.defaultProps = { usePosition: 'fixed' };

const Wrapper = styled.div`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: ${(props) => props.usePosition || 'fixed'};
  z-index: 4;
  opacity: 0.75;
  background: transparent;
  padding: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 2s;
  background: rgba(255, 255, 255, 0.7);
`;

const rotator = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: ${187 / 4};
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
`;

const colors = keyframes`
  0% {
    stroke: #ff3333;
  }
  25% {
    stroke: #0099cc;
  }
  50% {
    stroke: #ff6699;
  }
  75% {
    stroke: #009966;
  }
  100% {
    stroke: #ffcc33;
  }
`;

const myCircle = ({ className }) => (
  <circle
    className={className}
    fill="none"
    strokeWidth="6"
    strokeLinecap="round"
    cx="33"
    cy="33"
    r="30"
  />
);

myCircle.propTypes = { className: PropTypes.string.isRequired };

const StyledCircle = styled(myCircle)`
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    ${dash} 1.4s ease-in-out infinite,
    ${colors} ${1.4 * 4}s ease-in-out infinite;
`;

const StyledSpinner = styled.svg`
  animation: ${rotator} 1.4s linear infinite;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
`;

export default Loader;
