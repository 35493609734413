// TODO
// - [ ] Handle errors, retry, etc.
// - [ ] Validate routes

import extend from '../../lib/extend';
import sendError from '../../lib/sendError';
import { getSiteSettings, getUser } from '../authService';
import xhr from './requests';

const UNITY_BASE_URL = window.origin;

class UnityApi {
  constructor(path = '', endpoint = null, token = null) {
    const { unityApiEndpoint } = getSiteSettings();
    this.baseUrl = UNITY_BASE_URL;
    this.path = path;
    this.endpoint = endpoint || unityApiEndpoint;
    this.token = token;
    this.queue = [];
  }

  formatUrl = (p) => {
    let url = this.endpoint;
    const path = Number.isNaN(Number(p)) ? p : p.toString();
    url += this.path.charAt(0) === '/' ? this.path.substr(1) : this.path;

    if (path) {
      url += path.charAt() === '/' ? path.substr(1) : `/${path}`;
    }
    return url;
  };

  async fetchAccount() {
    try {
      const user = getUser();
      this.token = user?.access_token;
    } catch (err) {
      throw new Error(`DID NOT FETCH ACCOUNT: ${err}`);
    }
  }

  async request(obj = {}) {
    try {
      await this.fetchAccount();
      if (this.token === null) {
        throw new Error('No token');
      }

      return xhr({
        ...obj,
        url: this.formatUrl(obj.path),
        cache: false,
        headers: extend(obj.headers, {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        }),
      });
    } catch (error) {
      sendError(error);
      throw new Error(error);
    }
  }
}

export default UnityApi;
