import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { UserContext } from '../../context/UserContext';

const PREFIX = 'Footer';

const classes = {
  root: `${PREFIX}-root`,
  footer: `${PREFIX}-footer`,
};

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  [`& .${classes.footer}`]: {
    padding: '24px 16px',
    marginTop: 'auto',
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {'Copyright © '}
      Dallas Market Center {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function StickyFooter({ testMode, testSwipe }) {
  const { environment, registrationLocation, userName } =
    useContext(UserContext);
  const location = registrationLocation?.registrationLocationName;
  const [name] = userName?.split('@') || [];

  return (
    <Root className={classes.root}>
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          <Copyright />
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ textTransform: 'uppercase' }}
          >
            {environment} {location} {name}
          </Typography>
          {testMode && (
            <Typography variant="body2" color="textSecondary">
              Test Mode: Enabled and allows registration to rescan.
            </Typography>
          )}
          {testSwipe && (
            <Typography variant="body2" color="textSecondary">
              Test Swipe: Enabled and uses test card for payment.
            </Typography>
          )}
        </Container>
      </footer>
    </Root>
  );
}

StickyFooter.propTypes = {
  testMode: PropTypes.bool,
  testSwipe: PropTypes.bool,
};

StickyFooter.defaultProps = { testMode: false, testSwipe: false };
