import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Moment from 'react-moment';

const FormatDate = (props, name) => {
  return (
    <>
      Received &nbsp;
      <Moment fromNow>{props}</Moment>
      &nbsp; by {name}
    </>
  );
};

export default function NotificationList({
  count,
  data,
  receivedNotification,
}) {
  if (count === 0) {
    const listItems = (
      <ListItem key={Math.random() * 1000}>
        <ListItemText primary="There are no notifications" />
      </ListItem>
    );

    return <List>{listItems}</List>;
  }
  const listItems = data.map((item) => (
    <ListItem key={item.alertId}>
      <ListItemText
        primary={item.alertTitle}
        secondary={
          <>
            <Typography component="span" color="textPrimary">
              {item.alertDesc}
            </Typography>
            <Typography component="span">
              Severity : {item.alertSeverity.alertSeverityName}
            </Typography>
            {item.receivedOn
              ? FormatDate(item.receivedOn, item.createdByName)
              : ''}
          </>
        }
      />
      <ListItemSecondaryAction>
        <IconButton
          onClick={() => receivedNotification(item)}
          color={item.receivedOn ? 'primary' : 'default'}
          size="large"
        >
          <CheckCircle />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  ));

  return <List>{listItems}</List>;
}

NotificationList.propTypes = {
  count: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  receivedNotification: PropTypes.func.isRequired,
};
