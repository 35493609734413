import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import UnityApi from '../providers/core/api';
import UserConfiguration from '../providers/settings';
import { useAuth } from 'react-oidc-context';
import { getSiteSettings } from '../providers/authService';

const permissionLevel = {
  all: ['read', 'write', 'delete'],
  readWrite: ['read', 'write'],
  readOnly: ['read'],
};

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const auth = useAuth();
  const [user, setUser] = useState({});
  const [myTeam, setTeam] = useState([]);
  const [roles, setRoles] = useState([]);
  const [roleActions, setRoleActions] = useState([]);
  const [environment, setEnvironment] = useState('');
  const [searchState, setSearchState] = useState(null);
  const [registrationLocation, setRegistrationLocation] = useState(null);
  const [canEmail, setCanEmail] = useState(false);
  const [archivedUsers, setArchivedUsers] = useState([]);

  const fetchUser = async () => {
    const newUser = await new UnityApi('user/current').request();
    setUser(newUser);
  };

  const fetchTeam = async () => {
    const team = await new UnityApi('user/MyTeam').request();
    setTeam(team.map((u) => ({ userId: u.id, userName: u.userName })));
  };

  const fetchRoles = async () => {
    const currentRoles = await new UnityApi('role/current').request();
    setRoles(currentRoles);
  };

  const fetchRoleActions = async () => {
    const actions = await new UnityApi('role/roleActions').request();
    setRoleActions(
      actions.map((x) => ({
        ...x,
        permissions: permissionLevel[x.permissionLevel],
      }))
    );
  };

  const fetchArchivedUsers = async () => {
    const users = await new UnityApi('user/archived').request();
    setArchivedUsers(users);
  };

  useEffect(() => {
    if (auth.user.profile.idp === 'aad') {
      setCanEmail(true);
    }
  }, [auth.user]);

  const fetchSettings = async () => {
    const { environment } = getSiteSettings();
    setEnvironment(environment);
  };

  const fetchUserLocation = async () => {
    const id = await new UserConfiguration().get('registration-location');
    if (id) {
      const location = await new UnityApi(
        `registrationLocation/${id}`
      ).request();

      if (location) {
        setRegistrationLocation(location);
        return;
      }
    }

    const unknownLocation = await new UnityApi(
      'registrationLocation/default'
    ).request();
    setRegistrationLocation(unknownLocation);
  };

  useEffect(() => {
    fetchUser();
    fetchTeam();
    fetchRoles();
    fetchSettings();
    fetchRoleActions();
    fetchUserLocation();
    fetchArchivedUsers();
  }, []);

  return (
    <UserContext.Provider
      value={{
        ...user,
        myTeam,
        roleNames: roles,
        environment,
        roleActions,
        searchState,
        setSearchState,
        registrationLocation,
        setRegistrationLocation,
        fetchUserLocation,
        canEmail,
        archivedUsers,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
