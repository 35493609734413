import React, { Suspense } from 'react';
import styled from 'styled-components';
import RoleCheck from '../Authorization/RoleCheck';
import Loader from '../Loader';
import NavigationLink from './NavigationLink';
import views from './views';

const Navigation = () => (
  <NavContainer>
    <Suspense fallback={<Loader />}>
      {views.map((view) => {
        if (view.type) {
          return (
            <RoleCheck
              key={view.name}
              type={view.type}
              action="read"
              yes={() => <NavigationLink key={view.name} view={view} />}
            />
          );
        }
        return <NavigationLink key={view.name} view={view} />;
      })}
    </Suspense>
  </NavContainer>
);

const NavContainer = styled.div`
  z-index: 5;
  background: #002946;
`;

export default Navigation;
