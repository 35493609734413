import { User } from 'oidc-client-ts';

export function getUser() {
  var { unityIdentityEndpoint } = getSiteSettings();
  const oidcStorage = localStorage.getItem(
    `oidc.user:${unityIdentityEndpoint}:mcmc-unity-web`
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

const SETTINGS = 'siteSettings';

export function setSiteSettings(settings) {
  var settingString = JSON.stringify(settings);
  localStorage.setItem(SETTINGS, settingString);
}

export function getSiteSettings() {
  const settingString = localStorage.getItem(SETTINGS);
  return JSON.parse(settingString);
}

const RETURNURL = 'unityReturnUrl';

export function getReturnUrl() {
  return localStorage.getItem(RETURNURL);
}

export function setReturnUrl(path) {
  return localStorage.setItem(RETURNURL, path);
}
