import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { getReturnUrl, setSiteSettings } from '../providers/authService';
import App from './App';
import { WebStorageStateStore } from 'oidc-client';

export default function AuthApp() {
  const [oidcConfig, setOidcConfig] = useState(null);

  useEffect(() => {
    async function getSettings() {
      const response = await fetch('settings');
      const settings = await response.json();
      const { unityIdentityEndpoint, redirect } = settings;
      const config = {
        authority: unityIdentityEndpoint,
        client_id: 'mcmc-unity-web',
        redirect_uri: redirect,
        scope: 'openid profile offline_access unity_web',
        client_secret: 'QIwoF2KT4SKG72M9MZ0gj1AzxxhjZhSvqAfSMNIvMrg=',
        loadUserInfo: true,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
      };
      setSiteSettings(settings);
      setOidcConfig(config);
    }

    getSettings();
  }, []);

  const onSigninCallback = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
    const returnUrl = getReturnUrl();
    if (returnUrl) {
      window.location.replace(returnUrl);
    }
  };

  if (oidcConfig) {
    return (
      <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
        <App />
      </AuthProvider>
    );
  }
  return null;
}
