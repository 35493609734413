import UnityApi from '../providers/core/api';

export const invalidEmailMessage = 'Email verified as not safe for sending.';
export const duplicatePerson =
  'This person is already associated to this company';
const errorsNotToEmail = [
  'Abort',
  'An error occured',
  invalidEmailMessage,
  duplicatePerson,
];

export default function sendError({
  message: errorMessage,
  stack: stackTrace,
}) {
  if (errorsNotToEmail.some((x) => x === errorMessage)) {
    return;
  }

  const data = {
    type: 'JavaScript',
    errorMessage,
    stackTrace,
    url: window.location.href,
  };
  new UnityApi('Error').request({ method: 'POST', data });
}
