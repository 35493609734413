import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NavigationLink = ({ view }) => {
  if (view.href === null) {
    return (
      <div className="navigation-menu__item">
        {view.icon}
        <span className="navigation-menu__name">{view.name}</span>
      </div>
    );
  }
  return (
    <Link className="navigation-menu__item " to={view.href}>
      {view.icon}
      <span className="navigation-menu__name">{view.name}</span>
    </Link>
  );
};

export default NavigationLink;

NavigationLink.propTypes = { view: PropTypes.shape().isRequired };
