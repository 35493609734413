import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import HeaderSearch from './HeaderSearch';
import HeaderIcons from './HeaderIcons';
import { UserContext } from '../../context/UserContext';

const BLACK = '#000000';
const GREEN = '#009966';
const PINK = '#ff6699';
const RED = '#ff3333';
const BLUE = '#0099cc';
const YELLOW = '#ffcc33';

const Header = ({ searchType, setSearchType, query, setQuery }) => {
  const { environment } = useContext(UserContext);
  return (
    <HeaderContainer environment={environment}>
      <Link to="/">
        <h1 className="unity-identity">
          <span className="unity-identity__name">dmc</span>
          <span className="unity-identity__brand">{environment}</span>
        </h1>
      </Link>
      <HeaderSearch
        searchType={searchType}
        setSearchType={setSearchType}
        query={query}
        setQuery={setQuery}
      />
      <HeaderIcons />
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div.attrs((props) => {
  let color = BLUE;
  switch (props.environment) {
    case 'Unity Local':
      color = BLACK;
      break;
    case 'Unity DEV':
      color = GREEN;
      break;
    case 'Unity TEST':
      color = PINK;
      break;
    case 'Unity STAGE':
      color = RED;
      break;
    case 'Unity SAND':
      color = YELLOW;
      break;
    default:
      color = BLUE;
  }
  return { color };
})`
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(
    28deg,
    rgba(0, 166, 225, 0.9),
    ${(props) => props.color}
  );
  z-index: 1;
  a {
    margin-right: auto;

    &:visited,
    &:link {
      color: white;
      text-decoration: none;
    }
  }
`;

export default Header;

Header.propTypes = {
  searchType: PropTypes.string.isRequired,
  setSearchType: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
};
