import React from 'react';
import { KioskArrowAnimationContainer } from '../StyledComponents/Div';

export default function ArrowAnimation() {
  return (
    <KioskArrowAnimationContainer>
      <div className="text">Swipe to Pay</div>
      <div id="arrowAnim">
        <div className="arrowSliding">
          <div className="arrow red" />
        </div>
        <div className="arrowSliding delay1">
          <div className="arrow blue" />
        </div>
        <div className="arrowSliding delay2">
          <div className="arrow green" />
        </div>
        <div className="arrowSliding delay3">
          <div className="arrow yellow" />
        </div>
        <div className="arrowSliding delay4">
          <div className="arrow pink" />
        </div>
      </div>
    </KioskArrowAnimationContainer>
  );
}
