import React, { Children } from 'react';
import PropTypes from 'prop-types';

function Button({ style, className, onClick, children }) {
  return (
    <button type="button" style={style} className={className} onClick={onClick}>
      {Children.toArray(children)}
    </button>
  );
}

export default Button;

Button.propTypes = {
  style: PropTypes.shape(),
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
};
Button.defaultProps = { style: null, className: null };
