import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ViewIcon from '@mui/icons-material/Launch';

export const AddButton = styled.button`
  --blue: #0099cc;
  cursor: pointer;
  padding: 8px 16px;
  line-height: 1;
  color: white;
  display: inline-block;
  flex-direction: row;
  align-items: center;
  position: relative;
  white-space: nowrap;
  border: 1px solid var(--blue);
  border-radius: 4px;
  transition: 500ms all;
  background: var(--blue);
  &:hover {
    background: transparent;
    color: var(--blue);
  }
`;

export const PrintButton = styled.button`
  --green: #009966;
  cursor: pointer;
  color: var(--green);
  white-space: nowrap;
  transition: 500ms all;
`;

export const LargePrintButton = styled.button`
  --green: #009966;
  cursor: pointer;
  padding: 6px 12px;
  margin-left: 10px;
  color: white;
  white-space: nowrap;
  border: 1px solid var(--green);
  border-radius: 4px;
  transition: 500ms all;
  background: var(--green);
  font-size: 14px;
  display: inline-flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }

  &:hover {
    background: transparent;
    color: var(--green);
  }
`;

export const EditButton = styled.button`
  --yellow: #eec200;
  cursor: pointer;
  color: var(--yellow);
  white-space: nowrap;
  margin: 0;
  transition: 500ms all;
  margin-left: 12px;
`;

export const ButtonAdd = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding: 6px 14px;
  text-transform: uppercase;
  border-radius: 4px;
  background: #0099cc;
  color: white;
  transition: 1s all;
  &:hover {
    background: #0099cc80;
  }
`;

export const ResetButton = styled.button`
  background: #ffb9ff;
  padding: 8px 16px;
  border-radius: 4px;
  color: #210421;
  transition: 500ms all;

  &:hover {
    background: #d06ed0;
    color: white;
  }
`;

export const SubmitButton = styled.button`
  position: absolute;
  right: 16px;
  bottom: 16px;
  padding: 6px 16px;
  border-radius: 4px;
  color: white;
  background: #16b94f;
  transition: 500ms all;
  &:hover {
    background: #009966;
  }
  &:disabled {
    background: #aaaaaa;
  }
`;

export const Button = styled.button`
  color: white;
  margin-bottom: 10px;
  display: inline-block;
  padding: 6px 16px;
  background: #0099cc;
  border-radius: 4px;
`;

const AlertButtonCustom = styled.button`
  height: 22px;
  background: #ff333340;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  color: #710303;
  line-height: 1;
  transition: 1s all;
  &:hover {
    background: #ff333320;
  }
`;

export const AlertButtonSave = styled(AlertButtonCustom)`
  background: #0099cc;
  color: white;
  height: 34px;
  padding: 6px 16px;
  &:hover {
    background: #0099cc5;
  }
`;

export const AlertButtonRemove = styled(AlertButtonCustom)`
  background: #ff333340;
  color: #710303;
  &:hover {
    background: #ff333320;
  }
`;

export const ContractorEditButton = styled(EditButton)`
  position: relative;
  right: 5px;
  top: 5px;
  transition: all 1s;
  span {
    font-size: 0.8em;
    color: #999;
  }

  &:hover {
    color: orange;
  }
`;

const BuyListButton = styled.button`
  font-size: 12px;
  padding: 8px 16px;
  margin: 0 4px 0 0;
  line-height: 1;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  transition: all 500ms;
`;

export const CloseButton = styled(BuyListButton)`
  background: #eee;
  color: #333;
  &:hover {
    background: #ccc;
  }
`;

export const SaveButton = styled(BuyListButton)`
  background: #16b94f;
  color: white;
  &:hover {
    background: green;
  }
`;

export const DownloadButton = styled(BuyListButton)`
  background: #eee;
  color: #333;
  &:hover {
    background: #ccc;
  }
`;

export const PrintButtonStyle = styled.button`
  --green: #00996690;
  cursor: pointer;
  padding: 6px 12px;
  height: 32px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  right: 0;
  white-space: nowrap;
  border-radius: 4px;
  color: ${(props) => (props.allowed == null ? '#cccccc' : '#034017')};
  transition: 500ms all;
  background: ${(props) =>
    props.allowed == null ? '#eeeeee' : 'var(--green)'};
  border: none;

  &:hover {
    background: ${(props) => (props.allowed != null ? '#00996660' : '#eeeeee')};
    color: ${(props) => (props.allowed != null ? 'var(--green)' : '#cccccc')};
  }
  span {
    padding-left: 6px;
  }
`;

const ActionButton = styled.button`
  cursor: pointer;
  padding: 4px 8px;
  margin-left: 10px;
  line-height: 1;
  color: #583c35;
  white-space: nowrap;
  border: 1px solid var(--ohrage);
  border-radius: 4px;
  transition: 500ms all;
  background: var(--ohrage);
  font-size: 0.75em;
  margin: 5px 1px !important;
  display: inline-flex;
  align-items: center;
`;

export const WillCallEditButton = styled(ActionButton)`
  --yellow: #eec200;
  color: #794401;
  border: 1px solid var(--yellow);
  background: var(--yellow);

  &:hover {
    background: #ffd002;
    border-color: #ffd002;
  }
`;

export const WillCallDeleteButton = styled(ActionButton)`
  --ohrage: #bb1d00;
  color: white;
  border: 1px solid var(--ohrage);
  background: var(--ohrage);

  &:hover {
    background: #f33411;
    border-color: #f33411;
  }
`;

export const GuestButtonStyle = styled.button`
  --green: #00996690;
  cursor: pointer;
  padding: 6px 12px;
  height: 32px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  right: 0;
  white-space: nowrap;
  border-radius: 4px;
  color: ${(props) => (props.allowed == null ? '#cccccc' : '#034017')};
  transition: 500ms all;
  background: ${(props) =>
    props.allowed == null ? '#eeeeee' : 'var(--green)'};
  border: none;

  &:hover {
    background: ${(props) => (props.allowed != null ? '#00996660' : '#eeeeee')};
    color: ${(props) => (props.allowed != null ? 'var(--green)' : '#cccccc')};
  }
  span {
    padding-left: 6px;
  }
`;

export const CreateCompanyButton = styled.button`
  padding: 5px 8px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  background: #0099cc;
  color: white;
  display: flex;
  align-items: center;
  margin-right: 4px;
  transition: all 0.5s;
  &:hover {
    background: #00ace6;
  }
`;

export const ButtonSearch = styled.button`
  height: 30px;
  background: #0099cc;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  line-height: 1;
  margin-left: 5px;
  transition: 500ms all;

  &:hover {
    background: #0099cc40;
    color: #002946;
  }
`;

export const ButtonPaginate = styled.button`
  padding: 6px 12px;
  border-radius: 4px;
  margin: 0 5px;
  background: #eee;
  font-size: 12px;
  text-transform: uppercase;
`;

export const ButtonAddPerson = styled.button`
  display: flex;
  line-height: 1;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 4px;
  color: white;
  margin-right: 5px;
  background: #596587;
`;

export const ButtonCustom = styled.button`
  font-size: 12px;
  padding: 4px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
`;

export const ButtonSave = styled(ButtonCustom)`
  background: #009966;
  color: white;
`;

export const ButtonCopy = styled(ButtonSave)`
  margin-left: 5px;
`;

export const ButtonEdit = styled(ButtonCustom)`
  color: #6f5403;
  background: #ffcc3380;
`;

export const ButtonRemove = styled(ButtonCustom)`
  background: #ff3333;
  color: white;
  margin-left: 5px;
`;

export const ButtonHistory = styled(ButtonCustom)`
  background: #0099cc;
  color: white;
  margin-left: 5px;
`;

export const LeadsDeleteButton = styled.button`
  --ohrage: #fead9a;
  cursor: pointer;
  padding: 6px 12px;
  margin-left: 10px;
  line-height: 1.4285714286;
  color: #583c35;
  white-space: nowrap;
  border: 1px solid var(--ohrage);
  border-radius: 4px;
  transition: 500ms all;
  background: var(--ohrage);
  font-size: 14px;
  &:hover {
    background: transparent;
    color: var(--ohrage);
  }
`;

export const LeadsConvertButton = styled.button`
  --green: #009966;
  cursor: pointer;
  padding: 6px 12px;
  margin-left: 10px;
  color: white;
  white-space: nowrap;
  border: 1px solid var(--green);
  border-radius: 4px;
  transition: 500ms all;
  background: var(--green);
  font-size: 14px;
  &:hover {
    background: transparent;
    color: var(--green);
  }
`;

export const LeadsEditButton = styled.button`
  --yellow: #eec200;
  cursor: pointer;
  padding: 4px 8px;
  color: white;
  white-space: nowrap;
  margin: 0;
  border: 1px solid var(--yellow);
  border-radius: 4px;
  transition: 500ms all;
  background: var(--yellow);
  font-size: 14px;
  &:hover {
    background: transparent;
    color: var(--yellow);
  }
`;

export const ButtonCreate = styled.button`
  padding: 5px 8px;
  border-radius: 4px;
  background: #0099cc;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.75;
  transition: all 500ms;
  margin-top: 12px;

  &:hover {
    background: #00bbf9;
  }
`;

export const GroupEditButton = styled(EditIcon)`
  cursor: pointer;
  color: #eec200;
  position: absolute;
  right: 16px;

  &:hover {
    color: orange;
  }
`;

export const SavedFilterDropdownButton = styled.button`
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  background: #596587;
  color: white;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #59658770;
  }
`;

export const SavedFilterRemoveButton = styled.button`
  padding: 5px;
  float: right;
  z-index: 99;
  position: absolute;
  right: 6px;
  margin-top: 5px;
`;

export const SavedFilterItem = styled.button`
  padding: 12px 16px;
  width: 80%;
`;

export const SaveFilterButton = styled.button`
  background: rgba(0, 153, 204, 0.314);
  color: rgb(0, 41, 70) !important;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  padding: 12px 16px;
  width: 100%;
`;

export const DetailEditButton = styled(EditIcon)`
  position: absolute;
  right: 16px;
  top: 16px;
  transition: all 1s;
  cursor: pointer;
  color: #eec200;

  &:hover {
    color: orange;
  }
`;

export const ButtonPerson = styled.button`
  text-transform: uppercase;
  background: transparent;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 12px;
  border: 1px solid #ccc;
  transition: 1s all;
  &:hover {
    background: #eee;
  }
`;

export const ButtonCreatePerson = styled(ButtonPerson)`
  background: #0099cc50;
  border: 0;
  font-weight: bold;
  color: #076584;
`;

export const RemoveFromCompany = styled.button`
  background: ${(props) => (props.isActive ? `#BB1D00` : `#009966`)};
  padding: 6px 12px;
  color: white;
  border-radius: 4px;
  margin: 5px 0 10px;
  line-height: 1;
  text-transform: capitalize;
`;

export const DashBoardModuleButton = styled.button`
  display: flex;
  margin-left: auto;
  margin-right: 15px;
  color: #0099cc;

  &:hover {
    color: #000000;
  }
`;

export const BadgeHistoryButton = styled(AddButton)`
  margin: 0px;
  padding: 4px 8px;
  &:hover {
    color: white;
    background: #007095;
  }
`;

export const MergeAssociationsButton = styled(AddButton)`
  margin: 0px;
  width: 100%;
`;

export const AdvancedFilterButton = styled.button`
  vertical-align: top;
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  height: 1.875rem;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  font-size: 0.875rem;
  color: #2e3c43;
  font-weight: 700;
  line-height: 1;
  background: #fafafa;
  border-radius: 4px;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.1),
    inset 0 1px rgba(255, 255, 255, 0.2),
    0 2px rgba(0, 0, 0, 0.025);
  cursor: pointer;
  order: 2;
`;

export const PreviewButton = styled.button`
  border: 1px solid #818cab;
  border-radius: 4px;
  margin: auto 0 auto auto;
  width: 60px;
  height: 25px;
  background: white;
  font-size: 0.75em;
  float: right;
  display: none;
  &:hover {
    background: #818cab;
    color: white;
  }
`;

export const ThreadImport = styled(CloudUploadIcon)`
  float: right;
  &:hover {
    color: black;
  }
`;

export const IntegrationButton = styled(ViewIcon)`
  &:hover {
    color: #0099cc;
  }
`;
