import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function HeaderReport() {
  const mail = () => {
    window.location = `mailto:unity.developers@dallasmarketcenter.com?subject=Unity%20Issue&body=${window.location.href}`;
  };

  return (
    <button type="button" className="header-recents" onClick={mail}>
      <FontAwesomeIcon icon="history" size="lg" />
    </button>
  );
}
