import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { UserContext } from '../../context/UserContext';
import UnityApi from '../../providers/core/api';
import UserConfiguration from '../../providers/settings';
import FilteredDropDownList from '../Select/FilteredDropDownList';

export default function Location() {
  const { registrationLocation, setRegistrationLocation } =
    useContext(UserContext);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      const data = await new UnityApi('registrationlocation').request();
      setLocations(data.filter((x) => x.isActive));
    };
    fetchLocations();
  }, []);

  const locationOnChange = (e) => {
    setRegistrationLocation(e.target.value);
    new UserConfiguration().save(
      'registration-location',
      e.target.value.registrationLocationId
    );
  };

  return (
    <LocationDiv>
      <FilteredDropDownList
        data={locations}
        value={registrationLocation}
        textField="registrationLocationName"
        autoSort
        onChange={locationOnChange}
      />
    </LocationDiv>
  );
}

const LocationDiv = styled.div`
  margin-right: 10px;
  min-width: 250px;
  .k-dropdown {
    width: 100%;
  }
`;
