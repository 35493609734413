import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileImport } from '@fortawesome/free-solid-svg-icons/faFileImport';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons/faFileUpload';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons/faFolderOpen';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import { faToolbox } from '@fortawesome/free-solid-svg-icons/faToolbox';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';

library.add(
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faFileImport,
  faBars,
  faFileUpload,
  faUsers,
  faDownload,
  faTimes,
  faPlus,
  faChevronRight,
  faCheckCircle,
  faThLarge,
  faEdit,
  faLayerGroup,
  faEnvelope,
  faKey,
  faPrint,
  faChevronLeft,
  faUserCircle,
  faPaperPlane,
  faWrench,
  faBuilding,
  faTimesCircle,
  faHome,
  faSearch,
  faFolderOpen,
  faCog,
  faCogs,
  faToolbox,
  faBell,
  faHistory,
  faClock
);
