import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BadgePreviewContainer } from '../StyledComponents/Div';
import BadgePreview from '../Registration/BadgePreview';
import Coupon from '../Registration/Coupon';

export default function BadgePanel({
  badgeTemplate,
  componentRef,
  market,
  companyAssociation,
  guestBadges,
  tags,
}) {
  const [coupons, setCoupons] = useState([]);
  const marketId = market?.marketId || 0;
  const attendanceTypeId =
    companyAssociation?.associationType?.attendanceType?.attendanceTypeId || 0;
  const businessTypeId =
    companyAssociation?.company?.businessType?.businessTypeId || 0;

  const couponHtml = [...coupons, ...guestBadges];
  return (
    <div>
      <BadgePreviewContainer style={{ display: 'none' }}>
        <BadgePreview
          badgeTemplate={badgeTemplate && badgeTemplate.badgeHtml}
          badgeData={{ companyAssociation, market, tags }}
          couponHtml={couponHtml}
          ref={componentRef}
        />
        <Coupon
          marketId={marketId}
          attendanceTypeId={attendanceTypeId}
          businessTypeId={businessTypeId}
          setCouponHtml={setCoupons}
        />
      </BadgePreviewContainer>
    </div>
  );
}

BadgePanel.propTypes = {
  badgeTemplate: PropTypes.shape(),
  componentRef: PropTypes.shape().isRequired,
  market: PropTypes.shape().isRequired,
  companyAssociation: PropTypes.shape(),
  guestBadges: PropTypes.arrayOf(PropTypes.string).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape()),
};

BadgePanel.defaultProps = {
  companyAssociation: null,
  badgeTemplate: null,
  tags: null,
};
