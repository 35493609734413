import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
  Divider,
  Typography,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button as MaterialButton,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Input } from '@progress/kendo-react-inputs';
import { useIdleTimer } from 'react-idle-timer';
import {
  KioskBody,
  List,
  ListItem,
  KioskGuestContainer,
} from '../StyledComponents/Div';
import useGuestAssociationType from './useGuestAssociationType';
import { ButtonAdd as Button, ButtonRemove } from '../StyledComponents/Button';
import currencyFormat from '../../lib/currencyFormat';
import focusInput from '../../lib/focusInput';
import ArrowAnimation from './ArrowAnimation';
import { IDLETIMER_TIMEOUT, IDLETIMER_PROMT } from './constants';
import PrintButton from './PrintButton';
import { GUESTPRICE, BTEXCEPTIONS } from '../../lib/guestRegistrationUtil';
import PaymentPanel from './../Registration/GuestPayment';
const PREFIX = 'RegistrationPanel';

const classes = {
  titleFont: `${PREFIX}-titleFont`,
  card: `${PREFIX}-card`,
  nameText: `${PREFIX}-nameText`,
  kioskTextS: `${PREFIX}-kioskTextS`,
  kioskTextM: `${PREFIX}-kioskTextM`,
  kioskTextL: `${PREFIX}-kioskTextL`,
  captionFont: `${PREFIX}-captionFont`,
  dialogPaper: `${PREFIX}-dialogPaper`,
  guestList: `${PREFIX}-guestList`,
  guestName: `${PREFIX}-guestName`,
  total: `${PREFIX}-total`,
  main: `${PREFIX}-main`,
  button: `${PREFIX}-button`,
  redButton: `${PREFIX}-redButton`,
  greenButton: `${PREFIX}-greenButton`,
};

const StyledKioskBody = styled(KioskBody)(() => ({
  [`&.${classes.main}`]: {
    maxWidth: '90vw',
  },

  [`& .${classes.card}`]: {
    backgroundColor: 'rgb(218 218 218 / 80%)',
    padding: '1rem',
    marginBottom: 20,
    fontFamily: 'Work Sans,sans-serif !important',
  },

  [`& .${classes.button}`]: {
    width: '100%',
    marginBottom: 25,
    height: 110,
    borderRadius: 0,
    fontSize: '1.8rem',
    fontFamily: 'Work Sans,sans-serif',
  },

  [`& .${classes.greenButton}`]: {
    background: '#009966',
    '&:hover': {
      background: '#00996687',
    },
  },

  [`& .${classes.redButton}`]: {
    background: '#ff3333',
    '&:hover': {
      background: '#ff333387',
    },
  },

  [`& .${classes.guestList}`]: {
    marginTop: 20,
    '& p, & button': {
      fontSize: '2rem',
    },
  },

  [`& .${classes.guestName}`]: {
    width: '60%',
    textAlign: 'left',
    marginLeft: 10,
  },

  [`& .${classes.total}`]: {
    fontWeight: 'bold',
    marginTop: 10,
  },

  [`& .${classes.captionFont}`]: {
    paddingBottom: '20px',
    fontSize: '1.8rem',
    margin: '0 20px',
    fontStyle: 'italic',
  },

  [`& .${classes.titleFont}`]: {
    padding: '20px 0',
    textAlign: 'left',
    fontSize: '2rem',
    margin: '0 20px',
    fontFamily: 'Work Sans,sans-serif',
  },

  [`& .${classes.nameText}`]: {
    fontSize: '3rem',
    fontWeight: 600,
    padding: '10px 0',
  },

  [`& .${classes.dialogPaper}`]: {
    minWidth: 'calc(100vw - 500px)',
    minHeight: 'calc(100vh - 400px)',
    '& h2, & button': {
      fontSize: '2rem',
    },
  },

  [`& .${classes.kioskTextS}`]: {
    fontSize: '2rem !important',
    fontFamily: 'Work Sans,sans-serif !important',
  },

  [`& .${classes.kioskTextM}`]: {
    fontSize: '2.5rem !important',
    fontFamily: 'Work Sans,sans-serif !important',
  },

  [`& .${classes.kioskTextL}`]: {
    fontSize: '3rem !important',
    fontFamily: 'Work Sans,sans-serif !important',
  },
}));

export default function RegistrationPanel({
  market,
  companyAssociation,
  guests,
  updateGuests,
  handlePrint,
  vip,
  recordScan,
  cancel,
  printWithReciept,
  printing,
  testSwipe,
}) {
  const { courtesyType, paidType, clientType } = useGuestAssociationType();
  const [id, setId] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentRequired, setPaymentRequired] = useState(true);
  const [showPayment, setShowPayment] = useState(false);
  const [showGuests, setShowGuests] = useState(false);

  const amount = guests.length * GUESTPRICE;
  const guestLimit = vip ? 5 : 2;

  // payment required for jan,jun,sep gift or any first monday
  // exception for business type of Interior Designer/Architect/Builder
  useEffect(() => {
    if (vip) {
      setPaymentRequired(false);
    } else {
      setPaymentRequired(
        market.isGuestPaid &&
          !BTEXCEPTIONS.includes(
            companyAssociation?.company?.businessType?.businessTypeName
          )
      );
    }
  }, [companyAssociation, market, vip]);

  const BuyerInfo = () => {
    return (
      <div>
        <div className={classes.titleFont}>
          Please review your information for entry to DMC. Select an option to
          add a guest or to print your badge.
        </div>
        <div className={classes.card}>
          <AccountCircleIcon style={{ fontSize: '6rem' }} />
          <div className={classes.nameText}>
            {companyAssociation.person.fullName}
          </div>
          <div className={classes.kioskTextM}>
            {companyAssociation.company.companyName}
          </div>
          <div className={classes.kioskTextS}>{market.marketName}</div>
          {companyAssociation.person.primaryEmail && (
            <div style={{ fontSize: '1.5rem' }}>
              {companyAssociation.person.primaryEmail.emailAddress}
            </div>
          )}
        </div>
        <div className={classes.captionFont}>
          For further assistance, please speak to a registration staff member.
        </div>
      </div>
    );
  };

  const GuestPanel = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const clear = () => {
      setFirstName('');
      setLastName('');
      setErrorMessage(null);
    };

    const addGuest = () => {
      let guestAssociationType;
      if (paymentRequired) {
        guestAssociationType = paidType;
      } else if (vip) {
        guestAssociationType = clientType;
      } else {
        guestAssociationType = courtesyType;
      }

      const newGuest = {
        id,
        firstName,
        lastName,
        associationType: guestAssociationType,
      };
      guests.push(newGuest);
      updateGuests([...guests]);
      setId(id + 1);
      clear();
    };

    const removeGuest = (guest) => {
      const newList = guests.filter((x) => x.id !== guest.id);
      updateGuests(newList);
    };

    return (
      <div>
        <Dialog
          open={showGuests}
          onClose={() => setShowGuests(!showGuests)}
          classes={{ paper: classes.dialogPaper }}
        >
          <DialogTitle>Add Guest</DialogTitle>
          <DialogContent>
            <KioskGuestContainer>
              <Typography gutterBottom className={classes.kioskTextS}>
                Guests essential to business are welcomed{' '}
                {`(Limit to ${guestLimit} guests)`}. You may be subject to a $50
                non-refundable fee per added guest.
              </Typography>

              {!showPayment && guests.length < guestLimit && (
                <>
                  <Typography
                    style={{ paddingTop: 40, fontWeight: 600 }}
                    className={classes.kioskTextS}
                  >
                    Would you liked to add a guest?
                  </Typography>
                  <div
                    style={{
                      padding: '30px 0px 60px',
                      display: 'flex',
                    }}
                  >
                    <Input
                      label="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      ref={focusInput}
                      style={{ width: '100%', margin: 5 }}
                    />

                    <Input
                      label="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      style={{ width: '100%', margin: 5 }}
                    />
                  </div>
                </>
              )}

              {guests.length === guestLimit && (
                <Typography
                  className={classes.kioskTextS}
                  style={{ padding: '15px 0px 30px', fontWeight: 600 }}
                >
                  Sorry! You&apos;ve reached your guest limit.
                </Typography>
              )}

              <div className={classes.captionFont}>
                *To maintain the wholesale integrity, guest must adhere to the
                guest policy outlined by DMC. For additonal information, please
                speak to a registration staff member.
              </div>
            </KioskGuestContainer>
          </DialogContent>
          <DialogActions>
            <MaterialButton onClick={() => setShowGuests(!showGuests)}>
              Cancel
            </MaterialButton>
            <MaterialButton
              disabled={
                firstName.trim().length < 1 || lastName.trim().length < 1
              }
              onClick={() => {
                addGuest();
                setShowGuests(!showGuests);
              }}
              color="primary"
            >
              Confirm Guest
            </MaterialButton>
          </DialogActions>
        </Dialog>
        {guests.length > 0 && (
          <Container component="main" maxWidth="lg">
            <Typography className={classes.kioskTextS}> Guests</Typography>
            <Divider />
            <List className={classes.guestList}>
              {guests.map((g) => (
                <ListItem key={g.id}>
                  <Typography
                    className={classes.guestName}
                  >{`${g.firstName} ${g.lastName}`}</Typography>
                  {paymentRequired && (
                    <Typography>{currencyFormat.format(GUESTPRICE)}</Typography>
                  )}
                  <ButtonRemove
                    type="button"
                    onClick={() => removeGuest(g)}
                    style={{ width: '10%', margin: '5px' }}
                  >
                    <DeleteIcon style={{ fontSize: 20 }} alt="Delete" />
                  </ButtonRemove>
                </ListItem>
              ))}
              {paymentRequired && amount > 0 && (
                <>
                  <Divider />
                  <Typography className={classes.total}>
                    Total {currencyFormat.format(amount)}
                  </Typography>
                </>
              )}
            </List>
          </Container>
        )}
      </div>
    );
  };

  const PrintInProgress = () => {
    return (
      <Container>
        <CheckCircleIcon
          style={{
            fontSize: '10em',
            padding: 15,
            margin: 15,
            color: '#00AE6F',
          }}
        />
        <Typography
          style={{
            paddingBottom: '20px',
            fontWeight: 600,
            textTransform: 'uppercase',
            fontFamily: 'Roboto Condensed, sans-serif',
            fontSize: '6rem',
          }}
        >
          Thank You!
        </Typography>
        <div className={classes.card}>
          <Typography
            style={{ textTransform: 'capitalize', fontWeight: 600 }}
            className={classes.kioskTextL}
          >
            You&apos;re checked in
          </Typography>
          {(guests.length > 0 || !vip) && (
            <Typography
              style={{
                marginBottom: 10,
                marginTop: 20,
                textTransform: 'capitalize',
              }}
              className={classes.kioskTextM}
            >
              Please wait for your badge
            </Typography>
          )}
        </div>

        <Typography
          style={{ padding: '0 20px 20px 20px' }}
          className={classes.kioskTextS}
        >
          For any further assistance, please speak to a registration staff
          member
        </Typography>
        <LinearProgress />
      </Container>
    );
  };

  const [remaining, setRemaining] = useState(IDLETIMER_TIMEOUT);
  const [showPromtDialog, setShowPromtDialog] = useState(false);

  const onIdle = () => {
    // Do some idle action or close modal prompt
    cancel(); // exit of cancel out of kiosk registration
  };

  const onPrompt = () => {
    // Close the guest modal
    setShowGuests(false);
    // Fire a Modal Prompt
    setShowPromtDialog(true);
  };

  const { reset, getRemainingTime, isPrompted } = useIdleTimer({
    timeout: IDLETIMER_TIMEOUT,
    promptTimeout: IDLETIMER_PROMT,
    onPrompt,
    onIdle,
  });

  const handlePromtDialog = () => {
    // closed the promt dialog
    setShowPromtDialog(false);
    // reset idle timer
    reset();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(getRemainingTime()); // show remaining time on the prompt
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  const convertToSeconds = (millisecond) => {
    return Math.round(millisecond / 1000);
  };

  return (
    <StyledKioskBody className={classes.main}>
      {showPromtDialog && (
        <Dialog
          open={showPromtDialog}
          classes={{ paper: classes.dialogPaper }}
          style={{ zIndex: 10001 }}
        >
          <DialogContent>
            <Typography
              className={classes.kioskTextL}
              style={{ padding: '15px 0px 30px', fontWeight: 600 }}
            >
              Are You Still There?
            </Typography>

            <Typography
              className={classes.kioskTextM}
              style={{ padding: '15px 0px 30px' }}
            >
              Your session will automatically end in{' '}
              {convertToSeconds(remaining)} seconds
            </Typography>
          </DialogContent>
          <DialogActions>
            <MaterialButton onClick={() => cancel()}>Cancel</MaterialButton>
            <MaterialButton onClick={() => handlePromtDialog()} color="primary">
              Continue Session
            </MaterialButton>
          </DialogActions>
        </Dialog>
      )}

      {!printing && !showPayment && (
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Container>
              <BuyerInfo />
              <GuestPanel />
            </Container>
          </Grid>
          <Grid item xs={3}>
            <div>
              {!showPayment && (
                <Button
                  className={classes.button}
                  type="button"
                  onClick={() => setShowGuests(!showGuests)}
                >
                  Add Guest
                </Button>
              )}
              <Button
                className={`${classes.button} ${classes.redButton}`}
                type="button"
                onClick={() => cancel()}
              >
                Cancel
              </Button>
              {guests.length > 0 && paymentRequired ? (
                <>
                  {!showPayment && (
                    <Button
                      className={`${classes.button} ${classes.greenButton}`}
                      type="button"
                      onClick={() => setShowPayment(true)}
                    >
                      Proceed to Payment
                    </Button>
                  )}
                </>
              ) : (
                <PrintButton
                  onClick={() => handlePrint()}
                  showDialog={guests.length === 0}
                  addGuest={() => {
                    setShowGuests(!showGuests);
                  }}
                />
              )}
            </div>
          </Grid>
        </Grid>
      )}
      {showPayment && (
        <>
          {ReactDOM.createPortal(
            <ArrowAnimation />,
            document.getElementById('animation')
          )}
          <PaymentPanel
            amount={amount}
            guestQty={guests.length}
            setShowPayment={setShowPayment}
            showPayment={showPayment}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            printWithReciept={printWithReciept}
            testSwipe={testSwipe}
            isKioskView
          />
        </>
      )}
      {printing && <PrintInProgress />}
    </StyledKioskBody>
  );
}

RegistrationPanel.propTypes = {
  market: PropTypes.shape().isRequired,
  companyAssociation: PropTypes.shape().isRequired,
  guests: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateGuests: PropTypes.func.isRequired,
  handlePrint: PropTypes.func.isRequired,
  recordScan: PropTypes.func.isRequired,
  vip: PropTypes.bool,
  cancel: PropTypes.func.isRequired,
  printWithReciept: PropTypes.func.isRequired,
  printing: PropTypes.bool,
  testSwipe: PropTypes.bool,
};
RegistrationPanel.defaultProps = {
  vip: false,
  printing: false,
  testSwipe: false,
};
