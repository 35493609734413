import { useEffect, useState } from 'react';
import translateTemplate from '../lib/templating';
import ReceiptTemplate from '../components/FastPass/receiptTemplate';
import moment from 'moment';

export const GUESTPRICE = 50.0;
export const BTEXCEPTIONS = [
  'Registered Interior Designer',
  'Architect/Builder',
];
export const TESTSWIPECARD =
  '%B4111111111111111^LASTNAME/FIRSTNAME ^2412201000000000000000000000000?;4111111111111111=24122019010070400100?';

export const COURTESY = 'courtesy';
export const PAID = 'paid guest badge';
export const CLIENT = 'client';

export const checkGuestPaymentRequired = (market, guests) => {
  return (
    (market?.isGuestPaid || false) &&
    guests
      .map((x) => x?.associationType?.associationTypeName.toLowerCase())
      .includes(PAID)
  );
};

export const calculateTotalGuestPayment = (guests) => {
  const paidGuests = guests.filter(
    (x) => x.associationType?.associationTypeName.toLowerCase() === PAID
  );
  return {
    paymentAmount: paidGuests.length * GUESTPRICE,
    guestQty: paidGuests.length,
    guestPrice: GUESTPRICE,
  };
};

export const generatePaymentReceipt = (transactionResult) => {
  const template = translateTemplate(ReceiptTemplate(), {
    qty: transactionResult.guestQty,
    cost: transactionResult.guestPrice,
    total: transactionResult.paymentAmount,
    date: moment().format('MM/DD/YYYY'),
    transactionId: transactionResult?.pnref,
  });
  return template;
};

export const useTestMode = (location) => {
  const [testMode, setTestMode] = useState(false);
  const [testSwipe, setTestSwipe] = useState(false);

  useEffect(() => {
    const queryParameters = new URLSearchParams(location.search);

    if (queryParameters) {
      const paramTestMode = queryParameters.get('testmode');
      if (paramTestMode) {
        const isBool = paramTestMode.toLowerCase() === 'true';
        setTestMode(isBool);
        setTestSwipe(isBool);
      }

      const paramTestSwipe = queryParameters.get('testswipe');
      if (paramTestSwipe) {
        const isBool = paramTestSwipe.toLowerCase() === 'true';
        setTestSwipe(isBool);
      }
    }
  }, [location]);
  return { testMode, testSwipe };
};
