import React, { lazy } from 'react';
import Dashboard from '@mui/icons-material/Dashboard';
import Search from '@mui/icons-material/Search';
import People from '@mui/icons-material/People';
import AssignmentInd from '@mui/icons-material/AssignmentInd';
import Language from '@mui/icons-material/Language';
import ContractorIcon from '@mui/icons-material/WorkOutline';
import WillCallIcon from '@mui/icons-material/PlaylistAddCheck';
import DeckIcon from '@mui/icons-material/Deck';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';

const CreateUserAlertButton = lazy(
  () =>
    import(
      /* webpackChunkName: "createUserAlertButton" */ '../Alerts/CreateUserAlertButton'
    )
);
const ReportButton = lazy(
  () => import(/* webpackChunkName: "reportButton" */ '../Reports/ReportButton')
);
const VisitorButton = lazy(
  () =>
    import(/* webpackChunkName: "visitorButton" */ '../Visitor/VisitorButton')
);

const views = [
  { name: 'dashboard', href: '/', icon: <Dashboard /> },
  { name: 'search', href: '/search', icon: <Search /> },
  { name: 'groups', href: '/group', type: 'groups', icon: <People /> },
  { name: 'Visitor', href: null, type: 'visitor', icon: <VisitorButton /> },
  {
    name: 'Will Call',
    href: '/willCall',
    type: 'willCall',
    icon: <WillCallIcon />,
  },
  {
    name: 'contractor registration',
    href: '/contractor',
    type: 'contractor',
    icon: <ContractorIcon />,
  },
  { name: 'leads', href: '/leads', type: 'leads', icon: <AssignmentInd /> },
  { name: 'create User Alert', href: null, icon: <CreateUserAlertButton /> },
  { name: 'reports', href: null, icon: <ReportButton /> },
  {
    name: 'admin & organization',
    href: '/admin',
    type: 'admin',
    icon: <Language />,
  },
  {
    name: 'Booth Leasing',
    href: '/boothleasing',
    type: 'boothLeasing',
    icon: <DeckIcon />,
  },
  {
    name: 'Fastpass Kiosk',
    href: '/fastpass',
    type: 'fastpass',
    icon: <OfflineBoltIcon />,
  },
];

export default views;
