import React, { useContext, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';

const CurrentUser = () => {
  const { userName } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (e) => setAnchorEl(e.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const [name] = userName?.split('@') || [];

  return (
    <div className="header-user">
      <button
        aria-owns={anchorEl ? 'simple-menu' : null}
        aria-haspopup="true"
        onClick={handleMenuClick}
        type="button"
        style={{ color: 'white', padding: '5px' }}
      >
        {name}
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem component={Link} to="/account/settings">
          Profile Settings
        </MenuItem>
        <MenuItem component={Link} to="/account/signout">
          Logout
        </MenuItem>
      </Menu>
      <div className="header-user__container" />
    </div>
  );
};

export default CurrentUser;
