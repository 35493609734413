import PropTypes from 'prop-types';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

const rowLevelSecurity = ['groups'];

export function checkAction(roles, roleActions, type, action) {
  // this can be removed if we set all of the actions for admin and IT roles
  if (roles.includes('Admin') || roles.includes('InformationTechnology')) {
    return true;
  }

  const permissions = roleActions
    .filter((x) => x.actionName === type)
    .flatMap((x) => x.permissions);
  return permissions.includes(action);
}

export default function RoleCheck({ type, action, yes, no, row }) {
  const { roleNames: roles, roleActions } = useContext(UserContext);
  if (roles.length === 0) {
    return null;
  }
  // this can be removed if we set all of the actions for admin and IT roles
  if (roles.includes('Admin') || roles.includes('InformationTechnology')) {
    return yes();
  }
  if (roleActions.length === 0) {
    return null;
  }

  const authorized = checkAction(roles, roleActions, type, action);

  if (rowLevelSecurity.includes(type) && row) {
    // check row and rules
    return row.isRowAuthorized && authorized ? yes() : no();
  }
  return authorized ? yes() : no();
}

RoleCheck.defaultProps = {
  yes: () => null,
  no: () => null,
  row: null,
};

RoleCheck.propTypes = {
  type: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  yes: PropTypes.func,
  no: PropTypes.func,
  row: PropTypes.shape(),
};
