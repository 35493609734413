import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CouponContainer,
  CouponList,
  CouponListItem,
} from '../StyledComponents/Div';
import { CouponHeading } from '../StyledComponents/Span';
import UnityApi from '../../providers/core/api';

const CouponListComponent = ({ coupons }) => {
  const listItems = coupons.map((coupon) => (
    <CouponListItem key={coupon.couponId}>
      <h5>{coupon.couponName}</h5>
      <p>{coupon.couponDesc}</p>
      <span>{coupon.couponCode}</span>
      <div className="coupon-html">{coupon.couponHtml}</div>
    </CouponListItem>
  ));
  return <CouponList>{listItems}</CouponList>;
};

CouponListComponent.propTypes = {
  coupons: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

class Coupon extends Component {
  constructor(props) {
    super(props);
    this.state = { coupons: [] };
  }

  componentDidMount() {
    this.fetchCoupon();
  }

  componentDidUpdate(prevProps) {
    const { marketId, attendanceTypeId, businessTypeId, couponLimit } =
      this.props;
    if (
      marketId !== prevProps.marketId ||
      attendanceTypeId !== prevProps.attendanceTypeId ||
      businessTypeId !== prevProps.businessTypeId ||
      couponLimit !== prevProps.couponLimit
    ) {
      this.fetchCoupon();
    }
  }

  fetchCoupon = async () => {
    const {
      marketId = 0,
      attendanceTypeId = 0,
      businessTypeId = 0,
      couponLimit = 0,
      setCouponHtml,
    } = this.props;
    const url = `coupon/${marketId}/${attendanceTypeId}/${businessTypeId}/${couponLimit}`;
    const coupons = await new UnityApi(url).request();

    this.setState({ coupons: coupons || [] }, () =>
      setCouponHtml(coupons.map((x) => x.couponHtml))
    );
  };

  render() {
    const { coupons } = this.state;
    return coupons.length > 0 ? (
      <CouponContainer>
        <CouponHeading>Coupons</CouponHeading>
        <CouponListComponent coupons={coupons} />
      </CouponContainer>
    ) : null;
  }
}

export default Coupon;

Coupon.propTypes = {
  marketId: PropTypes.number,
  attendanceTypeId: PropTypes.number,
  businessTypeId: PropTypes.number,
  couponLimit: PropTypes.number,
  setCouponHtml: PropTypes.func.isRequired,
};
Coupon.defaultProps = {
  marketId: 0,
  attendanceTypeId: 0,
  businessTypeId: 0,
  couponLimit: 0,
};
