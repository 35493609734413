export const addressTypes = [
  {
    addressTypeId: 1,
    addressTypeName: 'Physical Address',
    isDefault: true,
    sortOrder: 10,
    createdOn: '2006-04-07T09:47:33.39',
    createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
    createdByName: null,
    modifiedOn: '2007-01-03T21:32:03.517',
    modifiedBy: null,
    modifiedByName: null,
    isRowAuthorized: false,
  },
  {
    addressTypeId: 6,
    addressTypeName: 'Public',
    isDefault: false,
    sortOrder: 60,
    createdOn: '2006-08-10T23:09:17.45',
    createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
    createdByName: null,
    modifiedOn: '2006-08-10T23:09:17.45',
    modifiedBy: null,
    modifiedByName: null,
    isRowAuthorized: false,
  },
];
export const states = [
  {
    stateId: 1,
    stateName: 'Alaska',
    stateAbbr: 'AK',
    country: {
      countryId: 1,
      countryName: 'USA',
      countryAbbr: null,
      countryCode: null,
      isInternational: false,
      createdOn: '2007-02-16T16:52:09',
      createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
      createdByName: null,
      modifiedOn: '2007-02-16T16:52:09',
      modifiedBy: null,
      modifiedByName: null,
      isRowAuthorized: false,
    },
    countryId: 1,
    createdOn: '2007-02-16T16:52:08',
    createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
    createdByName: null,
    modifiedOn: '2007-02-16T16:52:08',
    modifiedBy: null,
    modifiedByName: null,
    isRowAuthorized: false,
  },
  {
    stateId: 2,
    stateName: 'Alabama',
    stateAbbr: 'AL',
    country: {
      countryId: 1,
      countryName: 'USA',
      countryAbbr: null,
      countryCode: null,
      isInternational: false,
      createdOn: '2007-02-16T16:52:09',
      createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
      createdByName: null,
      modifiedOn: '2007-02-16T16:52:09',
      modifiedBy: null,
      modifiedByName: null,
      isRowAuthorized: false,
    },
    countryId: 1,
    createdOn: '2007-02-16T16:52:08',
    createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
    createdByName: null,
    modifiedOn: '2007-02-16T16:52:08',
    modifiedBy: null,
    modifiedByName: null,
    isRowAuthorized: false,
  },
];
export const countries = [
  {
    countryId: 1,
    countryName: 'USA',
    countryAbbr: null,
    countryCode: null,
    isInternational: false,
    createdOn: '2007-02-16T16:52:09',
    createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
    createdByName: null,
    modifiedOn: '2007-02-16T16:52:09',
    modifiedBy: null,
    modifiedByName: null,
    isRowAuthorized: false,
  },
  {
    countryId: 5,
    countryName: 'ALGERIA',
    countryAbbr: null,
    countryCode: null,
    isInternational: true,
    createdOn: '2007-02-16T16:52:09',
    createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
    createdByName: null,
    modifiedOn: '2007-02-16T16:52:09',
    modifiedBy: null,
    modifiedByName: null,
    isRowAuthorized: false,
  },
];
export const personAddresses = [
  {
    addressId: 334680,
    street1: '999 Oak',
    street2: null,
    city: 'DALLAS',
    state: {
      stateId: 48,
      stateName: 'Texas',
      stateAbbr: 'TX',
      country: null,
      countryId: 1,
      createdOn: '2007-02-16T16:52:08',
      createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
      createdByName: null,
      modifiedOn: '2007-02-16T16:52:08',
      modifiedBy: null,
      modifiedByName: null,
      isRowAuthorized: false,
    },
    postalCode: '75219',
    internationalAddress: null,
    country: {
      countryId: 1,
      countryName: 'USA',
      countryAbbr: null,
      countryCode: null,
      isInternational: false,
      createdOn: '2007-02-16T16:52:09',
      createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
      createdByName: null,
      modifiedOn: '2007-02-16T16:52:09',
      modifiedBy: null,
      modifiedByName: null,
      isRowAuthorized: false,
    },
    isActive: true,
    addressType: {
      addressTypeId: 1,
      addressTypeName: 'Physical Address',
      isDefault: true,
      sortOrder: 10,
      createdOn: '2006-04-07T09:47:33.39',
      createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
      createdByName: null,
      modifiedOn: '2007-01-03T21:32:03.517',
      modifiedBy: null,
      modifiedByName: null,
      isRowAuthorized: false,
    },
    isPrimary: true,
    internalUseOnly: false,
    personId: 225293,
    companyId: null,
    addressString: '999 Oak \nDALLAS, TX 75219',
    createdOn: '2019-11-22T14:09:00.713',
    createdBy: '722dd094-7955-4ad7-b29e-c37cde87d624',
    createdByName: 'gcate@dallasmarketcenter.com',
    modifiedOn: '2019-11-22T14:09:00.713',
    modifiedBy: '722dd094-7955-4ad7-b29e-c37cde87d624',
    modifiedByName: 'gcate@dallasmarketcenter.com',
    isRowAuthorized: false,
  },
];
export const companyAddresses = [
  {
    addressId: 334680,
    street1: '999 Oak',
    street2: null,
    city: 'DALLAS',
    state: {
      stateId: 48,
      stateName: 'Texas',
      stateAbbr: 'TX',
      country: null,
      countryId: 1,
      createdOn: '2007-02-16T16:52:08',
      createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
      createdByName: null,
      modifiedOn: '2007-02-16T16:52:08',
      modifiedBy: null,
      modifiedByName: null,
      isRowAuthorized: false,
    },
    postalCode: '75219',
    internationalAddress: null,
    country: {
      countryId: 1,
      countryName: 'USA',
      countryAbbr: null,
      countryCode: null,
      isInternational: false,
      createdOn: '2007-02-16T16:52:09',
      createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
      createdByName: null,
      modifiedOn: '2007-02-16T16:52:09',
      modifiedBy: null,
      modifiedByName: null,
      isRowAuthorized: false,
    },
    isActive: true,
    addressType: {
      addressTypeId: 1,
      addressTypeName: 'Physical Address',
      isDefault: true,
      sortOrder: 10,
      createdOn: '2006-04-07T09:47:33.39',
      createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
      createdByName: null,
      modifiedOn: '2007-01-03T21:32:03.517',
      modifiedBy: null,
      modifiedByName: null,
      isRowAuthorized: false,
    },
    isPrimary: true,
    internalUseOnly: false,
    personId: null,
    companyId: 225293,
    addressString: '999 Oak \nDALLAS, TX 75219',
    createdOn: '2019-11-22T14:09:00.713',
    createdBy: '722dd094-7955-4ad7-b29e-c37cde87d624',
    createdByName: 'gcate@dallasmarketcenter.com',
    modifiedOn: '2019-11-22T14:09:00.713',
    modifiedBy: '722dd094-7955-4ad7-b29e-c37cde87d624',
    modifiedByName: 'gcate@dallasmarketcenter.com',
    isRowAuthorized: false,
  },
];

export const registrations = [
  {
    registrationId: 100111,
    companyAssociation: {
      companyAssociationId: 0,
      companyId: 0,
      personId: 0,
      company: {
        buyerId: 0,
        businessType: {
          businessTypeId: 1,
          businessTypeName: 'Business Type',
          isActive: true,
          sortOrder: 30,
          createdOn: '2019-10-01T00:00:00',
          createdBy: '8a80ee3d-f039-4bf3-a58b-8e635b6cdaa6',
          createdByName: null,
          modifiedOn: null,
          modifiedBy: null,
          modifiedByName: null,
          isRowAuthorized: false,
        },
        businessTypeId: 1,
        majorStore: false,
        isProspect: false,
        mergeId: null,
        buyerAccessRecords: [],
        industries: null,
        companyId: 165830,
        companyName: 'Company Name',
        attendanceType: {
          attendanceTypeId: 1,
          attendanceTypeName: 'Buyer',
          isActive: true,
          createdOn: '2006-06-15T11:55:05.623',
          createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
          createdByName: null,
          modifiedOn: '2006-06-15T11:55:05.623',
          modifiedBy: null,
          modifiedByName: null,
          isRowAuthorized: false,
        },
        numberOfLocations: 1,
        doNotAdmit: false,
        isActive: true,
        inactiveReason: null,
        inactiveReasonDesc: null,
        annualSales: null,
        annualSalesId: null,
        companyMergeId: null,
        addresses: [
          {
            addressId: 224998,
            street1: '2100 Stemmons Freeway',
            street2: 'MS 60',
            city: 'Dallas',
            state: {
              stateId: 48,
              stateName: 'Texas',
              stateAbbr: 'TX',
              country: null,
              countryId: 1,
              createdOn: '2007-02-16T16:52:08',
              createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
              createdByName: null,
              modifiedOn: '2007-02-16T16:52:08',
              modifiedBy: null,
              modifiedByName: null,
              isRowAuthorized: false,
            },
            postalCode: '75207',
            internationalAddress: '2100 Stemmons Freeway MS 60 Dallas TX 75207',
            country: {
              countryId: 1,
              countryName: 'USA',
              countryAbbr: null,
              countryCode: null,
              isInternational: false,
              createdOn: '2007-02-16T16:52:09',
              createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
              createdByName: null,
              modifiedOn: '2007-02-16T16:52:09',
              modifiedBy: null,
              modifiedByName: null,
              isRowAuthorized: false,
            },
            isActive: true,
            addressType: {
              addressTypeId: 1,
              addressTypeName: 'Physical Address',
              isDefault: true,
              sortOrder: 10,
              createdOn: '2006-04-07T09:47:33.39',
              createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
              createdByName: null,
              modifiedOn: '2007-01-03T21:32:03.517',
              modifiedBy: null,
              modifiedByName: null,
              isRowAuthorized: false,
            },
            isPrimary: true,
            internalUseOnly: false,
            personId: null,
            companyId: 165830,
            addressString: '2100 Stemmons Freeway MS 60 Dallas TX 75207 \nUSA',
            createdOn: '2014-01-27T13:21:17.873',
            createdBy: '65d2cc3b-a18b-4c05-a595-ed0fda52872e',
            createdByName: 'lstreet',
            modifiedOn: '2014-01-27T13:21:17.873',
            modifiedBy: '8a80ee3d-f039-4bf3-a58b-8e635b6cdaa6',
            modifiedByName: 'Import',
            isRowAuthorized: false,
          },
        ],
        emailAddresses: [],
        phoneNumbers: [],
        websites: [],
        primaryAddress: {
          addressId: 224998,
          street1: '2100 Stemmons Freeway',
          street2: 'MS 60',
          city: 'Dallas',
          state: {
            stateId: 48,
            stateName: 'Texas',
            stateAbbr: 'TX',
            country: null,
            countryId: 1,
            createdOn: '2007-02-16T16:52:08',
            createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
            createdByName: null,
            modifiedOn: '2007-02-16T16:52:08',
            modifiedBy: null,
            modifiedByName: null,
            isRowAuthorized: false,
          },
          postalCode: '75207',
          internationalAddress: '2100 Stemmons Freeway MS 60 Dallas TX 75207',
          country: {
            countryId: 1,
            countryName: 'USA',
            countryAbbr: null,
            countryCode: null,
            isInternational: false,
            createdOn: '2007-02-16T16:52:09',
            createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
            createdByName: null,
            modifiedOn: '2007-02-16T16:52:09',
            modifiedBy: null,
            modifiedByName: null,
            isRowAuthorized: false,
          },
          isActive: true,
          addressType: {
            addressTypeId: 1,
            addressTypeName: 'Physical Address',
            isDefault: true,
            sortOrder: 10,
            createdOn: '2006-04-07T09:47:33.39',
            createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
            createdByName: null,
            modifiedOn: '2007-01-03T21:32:03.517',
            modifiedBy: null,
            modifiedByName: null,
            isRowAuthorized: false,
          },
          isPrimary: true,
          internalUseOnly: false,
          personId: null,
          companyId: 165830,
          addressString: '2100 Stemmons Freeway MS 60 Dallas TX 75207 \nUSA',
          createdOn: '2014-01-27T13:21:17.873',
          createdBy: '65d2cc3b-a18b-4c05-a595-ed0fda52872e',
          createdByName: 'lstreet',
          modifiedOn: '2014-01-27T13:21:17.873',
          modifiedBy: '8a80ee3d-f039-4bf3-a58b-8e635b6cdaa6',
          modifiedByName: 'Import',
          isRowAuthorized: false,
        },
        primaryEmail: null,
        primaryPhoneNumber: {},
        primaryWebsite: null,
        createdOn: '2014-01-27T13:21:17.867',
        createdBy: '65d2cc3b-a18b-4c05-a595-ed0fda52872e',
        createdByName: 'lstreet',
        modifiedOn: '2020-07-27T14:12:00.197',
        modifiedBy: '00000000-0000-0000-0000-000000000000',
        modifiedByName: null,
        isRowAuthorized: false,
      },
      person: {
        personId: 0,
        firstName: 'Firstname',
        preferredName: null,
        middleName: null,
        lastName: 'Lastname',
        personalTitle: null,
        personalTitleId: null,
        suffix: null,
        identificationNumber: null,
        identificationType: {},
        identificationTypeId: 1,
        doNotAdmit: false,
        isActive: true,
        inactiveReason: null,
        inactiveReasonDesc: null,
        mergeId: null,
        declinedEmail: false,
        companyAssociations: null,
        alerts: null,
        emailAddresses: [],
        addresses: [],
        phoneNumbers: [],
        primaryEmail: {},
        primaryPhoneNumber: {},
        fullName: 'Full Name',
        fullNameVar: null,
        createdOn: '2009-11-05T08:33:20.27',
        createdBy: '054f2437-a318-4a8e-a517-dd251f099694',
        createdByName: '',
        modifiedOn: '2019-12-09T14:50:36.113',
        modifiedBy: '746bb423-607b-45d8-8445-485e63c4f2c5',
        modifiedByName: '',
        isRowAuthorized: false,
      },
      associationType: {
        associationTypeId: 4,
        associationTypeName: 'OWNER',
        associationTypeDesc: 'OWNER',
        attendanceType: {
          attendanceTypeId: 1,
          attendanceTypeName: 'Buyer',
          isActive: true,
          createdOn: '2006-06-15T11:55:05.623',
          createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
          createdByName: null,
          modifiedOn: '2006-06-15T11:55:05.623',
          modifiedBy: null,
          modifiedByName: null,
          isRowAuthorized: false,
        },
        guestsAllowed: 0,
        product: null,
        isActive: true,
        sortOrder: 115,
        createdOn: '2006-09-19T14:57:06',
        createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
        createdByName: null,
        modifiedOn: '2006-09-19T14:57:06',
        modifiedBy: null,
        modifiedByName: null,
        isRowAuthorized: false,
      },
      associationTypeId: 4,
      title: 'Owner',
      alternateCompanyName: null,
      useAlternateCompanyName: false,
      isPrimaryCompany: true,
      isPrimaryPerson: true,
      inactiveReason: null,
      inactiveReasonDesc: null,
      isActive: true,
      referredBy: '',
      mergeId: null,
      categories: null,
      communications: null,
      incentives: null,
      invoices: null,
      qualifications: null,
      visitors: null,
      attendance: null,
      qualificationStatus: {
        isQualified: true,
        currentQualificationValue: 100.0,
        qualificationThreshold: 50.0,
      },
      productTypes: null,
      createdOn: '2014-01-27T13:21:17.877',
      createdBy: '8a80ee3d-f039-4bf3-a58b-8e635b6cdaa6',
      createdByName: 'Import',
      modifiedOn: '2019-12-31T11:48:32.417',
      modifiedBy: '8a80ee3d-f039-4bf3-a58b-8e635b6cdaa6',
      modifiedByName: 'Import',
      isRowAuthorized: false,
    },
    market: {
      marketId: 0,
      marketCode: 'MARKETCODE',
      marketName: 'MARKETNAME',
      marketDesc: '',
      marketType: {},
      registrationStartDate: new Date(),
      registrationEndDate: new Date().setDate(new Date().getDate() + 1),
      marketStartDate: new Date(),
      marketEndDate: new Date(),
      tempExhibitorStartDate: '',
      tempExhibitorEndDate: '',
      onlineRegistrationStartDate: '',
      isActive: true,
      industries: null,
      status: 1,
      parentMarket: null,
      shortName: 'SHORTNAME',
      createdOn: '',
      createdBy: '',
      createdByName: '',
      modifiedOn: '',
      modifiedBy: '',
      modifiedByName: '',
      isRowAuthorized: false,
      isGuestPaid: true,
    },
    registrationSource: {
      registrationSourceId: 2,
      registrationSourceName: 'Online',
      createdOn: '0001-01-01T00:00:00',
      createdBy: '00000000-0000-0000-0000-000000000000',
      createdByName: null,
      modifiedOn: null,
      modifiedBy: null,
      modifiedByName: null,
      isRowAuthorized: false,
    },
    guest: null,
    badgeData: {
      marketCode: 'TEST',
      marketName: 'TEST',
      companyName: 'TEST COMPANY',
      postalCode: '75207',
      stateAbbr: 'TX',
      stateName: 'Texas',
      city: 'Dallas',
      street1: '2100 Stemmons Freeway',
      street2: 'MS 60',
      internationalAddress: '2100 Stemmons Freeway MS 60 Dallas TX 75207',
      formattedAddress: '2100 Stemmons Freeway MS 60 Dallas TX 75207 \nUSA',
      firstName: 'Firstname',
      lastName: 'Lastname',
      fullName: 'Full Name',
      preferredName: null,
      hostName: null,
      associationType: 'OWNER',
      attendanceType: 'Buyer',
      newBuyerIndicator: null,
      primaryCategory: null,
      showroom: null,
      currentDate: '',
      building: null,
      visitorType: null,
    },
    createdOn: '2020-07-27T14:12:00.46',
    createdBy: '00000000-0000-0000-0000-000000000000',
    createdByName: null,
    modifiedOn: null,
    modifiedBy: null,
    modifiedByName: null,
    isRowAuthorized: false,
  },
  {
    registrationId: 100222,
    companyAssociation: {
      companyAssociationId: 0,
      companyId: 0,
      personId: 0,
      company: {
        exhibitorId: 0,
        dbaName: null,
        exhibitorDesc: '',
        exhibitorType: {
          exhibitorTypeId: 1,
          exhibitorTypeCode: 'SG',
          exhibitorTypeName: 'SG',
          exhibitorTypeDesc: 'DMC DATA IMPORT',
          isActive: true,
          sortOrder: 0,
          createdOn: '2008-06-26T15:55:02.793',
          createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
          createdByName: null,
          modifiedOn: '2008-06-26T15:55:02.793',
          modifiedBy: null,
          modifiedByName: null,
          isRowAuthorized: false,
        },
        exhibtitorTypeId: 0,
        customerNumber: null,
        exhibitorKey: 'EXHIBITORKEY',
        isTemporary: false,
        canOnlineBillPay: true,
        projectedRevenue: 0.0,
        exhibitorGroups: null,
        showrooms: [
          {
            showroomId: 845,
            showroomName: '100-WTC',
            showroomDesc: null,
            building: {
              buildingId: 1,
              buildingCode: 'WTC',
              buildingName: 'World Trade Center',
              floors: 15,
              isActive: true,
              createdOn: '2007-01-26T09:16:05',
              createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
              createdByName: null,
              modifiedOn: '2008-07-02T10:57:02.513',
              modifiedBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
              modifiedByName: null,
              isRowAuthorized: false,
            },
            floor: 1,
            isVirtual: false,
            isActive: true,
            exhibitorId: 0,
            isPrimary: false,
            exhibitorGroups: null,
            lines: null,
            roomNumber: '100',
            createdOn: '2002-08-19T11:20:00',
            createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
            createdByName: null,
            modifiedOn: '2008-06-26T15:55:02.827',
            modifiedBy: '4fb30062-02a2-45d8-8aa4-1b7004831c3f',
            modifiedByName: null,
            isRowAuthorized: false,
          },
        ],
        isProspect: false,
        mergeId: null,
        qualificationStatus: null,
        temporaries: [
          {
            temporaryId: 0,
            exhibitorId: 0,
            companyId: 0,
            market: {
              marketId: 0,
              marketCode: 'MARKETCODE',
              marketName: 'MARKETNAME',
              marketDesc: null,
              marketType: null,
              registrationStartDate: '2020-06-16T00:00:00',
              registrationEndDate: '2020-08-20T23:59:59',
              marketStartDate: '2020-08-16T00:00:00',
              marketEndDate: '2020-08-20T23:59:59',
              tempExhibitorStartDate: '2020-06-16T00:00:00',
              tempExhibitorEndDate: '2020-08-20T23:59:59',
              onlineRegistrationStartDate: '2020-07-01T00:00:00',
              isActive: true,
              industries: null,
              status: 0,
              parentMarket: null,
              shortName: '',
              createdOn: '2020-03-16T13:13:32.707',
              createdBy: '00000000-0000-0000-0000-000000000000',
              createdByName: null,
              modifiedOn: '2020-08-03T11:10:57.207',
              modifiedBy: '00000000-0000-0000-0000-000000000000',
              modifiedByName: null,
              isRowAuthorized: false,
            },
            productType: null,
            building: {
              buildingId: 1,
              buildingCode: 'WTC',
              buildingName: 'World Trade Center',
              floors: 15,
              isActive: true,
              createdOn: '2007-01-26T09:16:05',
              createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
              createdByName: null,
              modifiedOn: '2008-07-02T10:57:02.513',
              modifiedBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
              modifiedByName: null,
              isRowAuthorized: false,
            },
            floor: 1,
            boothNumber: '1222',
            paymentReceived: false,
            actualSales: 0.0,
            temporaryName: '1-1222-WTC',
            createdOn: '2020-07-31T11:19:47.797',
            createdBy: '00000000-0000-0000-0000-000000000000',
            createdByName: '',
            modifiedOn: '2020-08-03T11:10:04.337',
            modifiedBy: '00000000-0000-0000-0000-000000000000',
            modifiedByName: '',
            isRowAuthorized: false,
          },
        ],
        primaryShowroom: '100-WTC',
        allShowrooms: 'ADVREG 1-122222 Gift, WTC 1-1222 Gift, 10000, 100',
        industries: null,
        companyId: 0,
        companyName: 'Company Name',
        attendanceType: {
          attendanceTypeId: 3,
          attendanceTypeName: 'Exhibitor',
          isActive: true,
          createdOn: '2006-06-15T11:55:31.297',
          createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
          createdByName: null,
          modifiedOn: '2006-06-15T11:55:31.297',
          modifiedBy: null,
          modifiedByName: null,
          isRowAuthorized: false,
        },
        numberOfLocations: 1,
        doNotAdmit: false,
        isActive: true,
        inactiveReason: null,
        inactiveReasonDesc: null,
        annualSales: null,
        annualSalesId: null,
        companyMergeId: null,
        addresses: [
          {
            addressId: 334723,
            street1: 'Test',
            street2: 'Test',
            city: 'test',
            state: {
              stateId: 2,
              stateName: 'Alabama',
              stateAbbr: 'AL',
              country: null,
              countryId: 1,
              createdOn: '2007-02-16T16:52:08',
              createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
              createdByName: null,
              modifiedOn: '2007-02-16T16:52:08',
              modifiedBy: null,
              modifiedByName: null,
              isRowAuthorized: false,
            },
            postalCode: '75200',
            internationalAddress: '',
            country: {
              countryId: 1,
              countryName: 'USA',
              countryAbbr: null,
              countryCode: null,
              isInternational: false,
              createdOn: '2007-02-16T16:52:09',
              createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
              createdByName: null,
              modifiedOn: '2007-02-16T16:52:09',
              modifiedBy: null,
              modifiedByName: null,
              isRowAuthorized: false,
            },
            isActive: true,
            addressType: {
              addressTypeId: 1,
              addressTypeName: 'Physical Address',
              isDefault: true,
              sortOrder: 10,
              createdOn: '2006-04-07T09:47:33.39',
              createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
              createdByName: null,
              modifiedOn: '2007-01-03T21:32:03.517',
              modifiedBy: null,
              modifiedByName: null,
              isRowAuthorized: false,
            },
            isPrimary: true,
            internalUseOnly: false,
            personId: null,
            companyId: 225308,
            addressString: 'Test Test \ntest, AL 75200',
            createdOn: '2020-02-28T14:27:12.85',
            createdBy: '00000000-0000-0000-0000-000000000000',
            createdByName: '',
            modifiedOn: '2020-06-26T11:15:02.92',
            modifiedBy: '00000000-0000-0000-0000-000000000000',
            modifiedByName: '',
            isRowAuthorized: false,
          },
        ],
        emailAddresses: [],
        phoneNumbers: [],
        websites: [],
        primaryAddress: {
          addressId: 334723,
          street1: 'Test',
          street2: 'Test',
          city: 'test',
          state: {
            stateId: 2,
            stateName: 'Alabama',
            stateAbbr: 'AL',
            country: null,
            countryId: 1,
            createdOn: '2007-02-16T16:52:08',
            createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
            createdByName: null,
            modifiedOn: '2007-02-16T16:52:08',
            modifiedBy: null,
            modifiedByName: null,
            isRowAuthorized: false,
          },
          postalCode: '75200',
          internationalAddress: '',
          country: {
            countryId: 1,
            countryName: 'USA',
            countryAbbr: null,
            countryCode: null,
            isInternational: false,
            createdOn: '2007-02-16T16:52:09',
            createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
            createdByName: null,
            modifiedOn: '2007-02-16T16:52:09',
            modifiedBy: null,
            modifiedByName: null,
            isRowAuthorized: false,
          },
          isActive: true,
          addressType: {
            addressTypeId: 1,
            addressTypeName: 'Physical Address',
            isDefault: true,
            sortOrder: 10,
            createdOn: '2006-04-07T09:47:33.39',
            createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
            createdByName: null,
            modifiedOn: '2007-01-03T21:32:03.517',
            modifiedBy: null,
            modifiedByName: null,
            isRowAuthorized: false,
          },
          isPrimary: true,
          internalUseOnly: false,
          personId: null,
          companyId: 225308,
          addressString: 'Test Test \ntest, TX 75200',
          createdOn: '2020-02-28T14:27:12.85',
          createdBy: '00000000-0000-0000-0000-000000000000',
          createdByName: '',
          modifiedOn: '2020-06-26T11:15:02.92',
          modifiedBy: '00000000-0000-0000-0000-000000000000',
          modifiedByName: '',
          isRowAuthorized: false,
        },
        primaryEmail: null,
      },
      person: {
        personId: 652221,
        firstName: 'Firstname',
        preferredName: null,
        middleName: null,
        lastName: 'Lastname',
        personalTitle: null,
        personalTitleId: null,
        suffix: null,
        identificationNumber: null,
        identificationType: null,
        identificationTypeId: null,
        doNotAdmit: false,
        isActive: true,
        inactiveReason: null,
        inactiveReasonDesc: null,
        mergeId: null,
        declinedEmail: false,
        companyAssociations: null,
        alerts: null,
        emailAddresses: [],
        addresses: [],
        phoneNumbers: [],
        primaryEmail: {
          emailId: 0,
          emailAddress: 'emailaddress@dallasmarketcenter.com',
          isActive: true,
          emailStatus: {
            emailStatusId: 1,
            accepted: true,
            emailStatusName: 'Valid',
            createdOn: '2019-10-25T11:55:29.06',
            createdBy: '8a80ee3d-f039-4bf3-a58b-8e635b6cdaa6',
            createdByName: null,
            modifiedOn: null,
            modifiedBy: null,
            modifiedByName: null,
            isRowAuthorized: false,
          },
          emailStatusId: 1,
          emailType: {
            emailTypeId: 1,
            emailTypeName: 'General',
            isDefault: true,
            sortOrder: 10,
            createdOn: '2006-04-07T10:54:33.123',
            createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
            createdByName: null,
            modifiedOn: '2006-07-06T11:54:27.047',
            modifiedBy: null,
            modifiedByName: null,
            isRowAuthorized: false,
          },
          emailTypeId: 1,
          isPrimary: true,
          internalUseOnly: false,
          companyId: null,
          personId: 652221,
          isVerified: false,
          createdOn: '2019-12-27T11:09:05.343',
          createdBy: '00000000-0000-0000-0000-000000000000',
          createdByName: '',
          modifiedOn: '2020-01-17T13:35:51.58',
          modifiedBy: '00000000-0000-0000-0000-000000000000',
          modifiedByName: '',
          isRowAuthorized: false,
        },
        primaryPhoneNumber: null,
        fullName: 'Full Name',
        fullNameVar: null,
        createdOn: '2019-12-27T11:09:05.33',
        createdBy: '00000000-0000-0000-0000-000000000000',
        createdByName: '',
        modifiedOn: '2020-08-18T10:01:18.323',
        modifiedBy: '00000000-0000-0000-0000-000000000000',
        modifiedByName: '',
        isRowAuthorized: false,
      },
      associationType: {
        associationTypeId: 22,
        associationTypeName: 'EXHIBITOR',
        associationTypeDesc: 'EXHIBITOR',
        attendanceType: {
          attendanceTypeId: 3,
          attendanceTypeName: 'Exhibitor',
          isActive: true,
          createdOn: '2006-06-15T11:55:31.297',
          createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
          createdByName: null,
          modifiedOn: '2006-06-15T11:55:31.297',
          modifiedBy: null,
          modifiedByName: null,
          isRowAuthorized: false,
        },
        guestsAllowed: 0,
        product: null,
        isActive: true,
        sortOrder: 70,
        createdOn: '2007-01-10T12:20:33.923',
        createdBy: '6dd20770-2193-454a-8b71-91d8b441fd5e',
        createdByName: null,
        modifiedOn: '2007-01-10T12:20:33.923',
        modifiedBy: null,
        modifiedByName: null,
        isRowAuthorized: false,
      },
      associationTypeId: 22,
      title: null,
      alternateCompanyName: null,
      useAlternateCompanyName: false,
      isPrimaryCompany: false,
      isPrimaryPerson: false,
      inactiveReason: null,
      inactiveReasonDesc: null,
      isActive: true,
      referredBy: null,
      mergeId: null,
      categories: null,
      communications: null,
      incentives: null,
      invoices: null,
      qualifications: null,
      visitors: null,
      attendance: null,
      qualificationStatus: {
        isQualified: true,
        currentQualificationValue: 0.0,
        qualificationThreshold: 50.0,
      },
      productTypes: null,
      createdOn: '2019-12-27T11:09:05.343',
      createdBy: '00000000-0000-0000-0000-000000000000',
      createdByName: '',
      modifiedOn: '2020-08-18T10:01:18.31',
      modifiedBy: '00000000-0000-0000-0000-000000000000',
      modifiedByName: '',
      isRowAuthorized: false,
    },
    market: {
      marketId: 0,
      marketCode: 'MARKETCODE',
      marketName: 'MARKETNAME',
      marketDesc: '',
      marketType: {},
      registrationStartDate: new Date(),
      registrationEndDate: new Date().setDate(new Date().getDate() + 1),
      marketStartDate: new Date(),
      marketEndDate: new Date(),
      tempExhibitorStartDate: new Date(),
      tempExhibitorEndDate: new Date(),
      onlineRegistrationStartDate: null,
      isActive: true,
      industries: null,
      status: 1,
      parentMarket: null,
      shortName: '',
      createdOn: '2019-07-19T06:47:57.603',
      createdBy: '00000000-0000-0000-0000-000000000000',
      createdByName: '',
      modifiedOn: '2020-07-27T14:12:32.643',
      modifiedBy: '00000000-0000-0000-0000-000000000000',
      modifiedByName: '',
      isRowAuthorized: false,
    },
    registrationSource: {
      registrationSourceId: 2,
      registrationSourceName: 'Online',
      createdOn: '0001-01-01T00:00:00',
      createdBy: '00000000-0000-0000-0000-000000000000',
      createdByName: null,
      modifiedOn: null,
      modifiedBy: null,
      modifiedByName: null,
      isRowAuthorized: false,
    },
    guest: null,
    badgeData: {
      marketCode: 'MARKETCODE',
      marketName: 'MARKETNAME',
      companyName: 'COMPANYNAME',
      postalCode: '75200',
      stateAbbr: 'TX',
      stateName: 'Texas',
      city: 'test',
      street1: 'Test',
      street2: 'Test',
      internationalAddress: '',
      formattedAddress: 'Test Test \ntest, TX 75200',
      firstName: 'Arraya',
      lastName: 'FIRSTNAME',
      fullName: 'FULL ANME',
      preferredName: null,
      hostName: null,
      associationType: 'EXHIBITOR',
      attendanceType: 'Exhibitor',
      newBuyerIndicator: null,
      primaryCategory: null,
      showroom: null,
      currentDate: '08/18/2020',
      building: null,
      visitorType: null,
    },
    createdOn: '2020-08-18T10:01:20.227',
    createdBy: '00000000-0000-0000-0000-000000000000',
    createdByName: null,
    modifiedOn: null,
    modifiedBy: null,
    modifiedByName: null,
    isRowAuthorized: false,
  },
];
