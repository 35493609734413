export const BadgePrintStyle = `@media print {
  @page {
    margin: 5mm;
  }
  .coupon, .guest {
      display: block;
      page-break-before: always;
    }
  .qrcode {
    bottom: 0;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
  }
  hr{
    border: 1px solid black;
  }
}`;
